import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
  useMemo,
  ReactNode,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import Select from 'react-select'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Tab,
  Tabs,
  MenuItem,
  InputLabel,
  TextField,
  FormLabel,
  FormControl,
  Slider,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  ButtonProps,
  RadioGroup,
  Radio,
  ClickAwayListener,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Refresh,
} from '@mui/icons-material'
import TaskView from '../TaskView/TaskView'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as expressionutil from '../util/expressionutil'
import { SelectOption, YYYYMMDDDateString } from '../types/common'
import { Controller, useForm } from 'react-hook-form'
import {
  CreateSongPracticeTaskFormData,
  CreateSongPracticeTaskFormDataSchema,
  SongDefiningMethod,
} from './common'
import { PracticeSongOutputDto } from '../types/practiceSong'
import { GradientButton } from '../Components/Button'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  GRAY,
  GRAY_DARK,
  GRAY_LIGHT,
  GREEN,
  GREEN_800,
  GREEN_LIGHT,
  GREEN_LIGHTEST,
} from '../theme/theme'
import { ErrorMessage } from '@hookform/error-message'
import { getCreateSongPageUrl } from '../config/config'
import { DevTool } from '@hookform/devtools'
import CreateSong from '../Practice/CreateSong'
import { useTranslation } from 'react-i18next'
import { E } from '../pixel'

export interface AddSongTaskFormViewProps {
  date: YYYYMMDDDateString
  onSubmit: (data: CreateSongPracticeTaskFormData) => void
}

const INDENT_PADDING_PX = 0

export default function AddSongTaskFormView({
  date,
  onSubmit,
}: AddSongTaskFormViewProps) {
  const { t } = useTranslation()
  const [openSongCreate, setOpenSongCreate] = useState(false)
  const { handleSubmit, control, register, getValues } =
    useForm<CreateSongPracticeTaskFormData>({
      resolver: zodResolver(CreateSongPracticeTaskFormDataSchema),
    })

  const _onSubmit = async (formData: CreateSongPracticeTaskFormData) => {
    E('CreatePracticeTask', {
      practiceCategory: 'SONG',
      taskSongId: formData.practiceSongId,
      taskSongName: songOptions.find(
        (option) => option.value == formData.practiceSongId,
      )?.label,
      taskTitle: formData.title,
    })
    const reqBody = {
      ...formData,
      practiceTaskCategory: 'SONG',
      date: date,
      todo: {
        goalDuration: 0,
        isDone: false,
      },
    }
    const resp = await repoutil.post('practiceTasks', reqBody)
    if (!resp.ok) {
      console.error(resp.status)
      return
    }
    const data = await resp.json()
    if (data['error']) {
      console.error(data['error'])
    }

    console.log(data)
    onSubmit(formData)
  }

  const onCloseAddSongTaskDialog = async (obj: object, reason: string) => {
    setOpenSongCreate(false)
    return
  }

  const [songOptions, setSongOptions] = useState<SelectOption<number>[]>([])
  const fetchSongs = async () => {
    const resp = await repoutil.http(`practiceSongs?songKind=RELEASED`)
    const data = await resp.json()
    setSongOptions(
      (data.data as PracticeSongOutputDto[]).map((song) => ({
        value: song.id,
        label: expressionutil.songTitleWithArtists(song),
      })),
    )
    return data.data
  }

  useEffect(() => {
    fetchSongs()
  }, [])

  const onClickAddSong = () => {
    setOpenSongCreate(true)
  }

  return (
    <form>
      <Box className="form-input-box" mb={3}>
        <Stack spacing={2}>
          <div>
            <div className="flex">
              <p className="flex-grow text-gray-800 mb-1">{t('SONG')}</p>
              <p className="text-gray-500 underline" onClick={onClickAddSong}>
                {t('ADD_NEW_SONG')}
              </p>
              <Dialog
                open={openSongCreate}
                onClose={onCloseAddSongTaskDialog}
                style={{ width: '100%' }}
                PaperProps={{
                  style: {
                    width: '85%',
                    maxHeight: '95%',
                    margin: '0px',
                    scrollbarWidth: 'none',
                    borderRadius: '8px',
                  },
                }}
              >
                <DialogContent>
                  <CreateSong
                    onSubmit={() => {
                      fetchSongs()
                      setOpenSongCreate(false)
                    }}
                  />
                </DialogContent>
              </Dialog>
            </div>
            <div>
              <Controller
                control={control}
                name="practiceSongId"
                render={({
                  field: { name, onChange, value, ref },
                  formState: { errors },
                }) => (
                  <>
                    <Select<SelectOption<number>>
                      placeholder={t('PLACEHOLDER_SONG_SELECTION')}
                      ref={ref}
                      value={songOptions.find(
                        (option) => option.value === value,
                      )}
                      onChange={(option) => onChange(option?.value)}
                      options={songOptions}
                      menuPosition="fixed"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          // Generated by ChatGPT based on the primary color #34C61C
                          primary: '#34C61C', // Your primary green color
                          primary75: '#4FDA36', // Slightly lighter green (75% transparency)
                          primary50: '#8FF285', // Even lighter green (50% transparency)
                          primary25: '#BFF7BA', // Lightest green (25% transparency)
                          neutral0: '#ffffff', // Background color (white)
                          neutral5: '#f6f6f6', // Light grey for subtle elements
                          neutral10: '#e6e6e6', // Light grey for borders
                          neutral20: '#cccccc', // Border color
                          neutral30: '#b3b3b3', // Hover border color
                          neutral40: '#999999', // Disabled text color
                          neutral50: '#666666', // Default text color
                          neutral60: '#4d4d4d', // Active text color
                          neutral70: '#333333', // Hover text color
                          neutral80: '#1a1a1a', // Focused text color
                          neutral90: '#0d0d0d', // Darkest text color
                          danger: '#d9534f', // Danger color (red)
                          dangerLight: '#f2dede', // Light danger color
                        },
                      })}
                      styles={{
                        menuList: (base) => ({ ...base }),
                      }}
                    />

                    <ErrorMessage
                      errors={errors}
                      name={name}
                      render={({ message }) => (
                        <Typography variant={'caption'} color={'error'}>
                          {`${message}`}
                        </Typography>
                      )}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <div className="flex">
              <p className="flex-grow text-gray-800 mb-1">
                {t('ADDITIONAL_NOTE')}
              </p>
            </div>
            <div>
              <Controller
                control={control}
                name="title"
                render={({ field, formState: { errors } }) => (
                  <>
                    <TextField
                      placeholder={t('PLACEHOLDER_ADDITIONAL_NOTE')}
                      fullWidth={true}
                      margin="dense"
                      inputProps={{
                        style: { paddingTop: '8px', paddingBottom: '8px' },
                      }}
                      {...field}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={field.name}
                      render={({ message }) => (
                        <Typography variant={'caption'} color={'error'}>
                          {`${message}`}
                        </Typography>
                      )}
                    />
                  </>
                )}
              />
            </div>
          </div>
          {/* <Typography>최근 추가한 연습</Typography> */}
        </Stack>
      </Box>
      <GradientButton
        style={{
          fontSize: '16px',
          textTransform: 'none',
          borderRadius: '10px',
          fontWeight: 'bold',
        }}
        disableElevation={true}
        onClick={handleSubmit(_onSubmit)}
      >
        {t('ADD')}
      </GradientButton>
      {process.env.NODE_ENV === 'development' && (
        <DevTool control={control} placement="top-right" />
      )}
    </form>
  )
}
