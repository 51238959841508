import { SetterOrUpdater, useRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { PracticeSongOutputDto } from '../types/practiceSong'
import {
  practiceSongsState,
  retrievePracticeSongs,
} from '../state/practicesong'

export default function usePracticeSongs(): [
  Array<PracticeSongOutputDto>,
  SetterOrUpdater<Array<PracticeSongOutputDto>>,
] {
  const [practiceSongs, setPracticeSongs] =
    useRecoilState<Array<PracticeSongOutputDto>>(practiceSongsState)

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken'),
  )

  const refreshPracticeSongs = async () => {
    const newPracticeSongs = await retrievePracticeSongs()
    setPracticeSongs(newPracticeSongs)
  }

  useEffect(() => {
    const newAccessToken = localStorage.getItem('accessToken')

    // XXX: accessToken에 dependency가 걸려있으면서,
    // accessToken을 수정하기 때문에 무한 루프에 빠질 수 있음
    // 이를 조심해야함.
    if (accessToken !== newAccessToken) {
      setAccessToken(newAccessToken)
      return
    }

    refreshPracticeSongs()
  }, [accessToken])

  return [practiceSongs, setPracticeSongs]
}
