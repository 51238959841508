import React, {
  Fragment,
  useState,
  useRef,
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  IconButton,
  AppBar,
  Toolbar,
  TextField,
  Stack,
  Container,
  Paper,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Input from '@mui/material/Input'
import VolumeUp from '@mui/icons-material/VolumeUp'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  CameraAlt,
  Menu,
  Check,
  Delete,
  DragHandle,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { useLottie } from 'lottie-react'
import { BLACK, GRAY, GRAY_DARK, GRAY_DARKEST } from '../theme/theme'
import { YYYYMMDDDateString } from '../types/common'
import { PracticeTaskOutputDto } from '../types/practiceTask'
import { practiceCategories } from '../PracticeDayView/common'
import { getSongPageBaseUrl, getUpdateSongPageUrl } from '../config/config'
import { useRecoilState } from 'recoil'
import { set } from 'date-fns'
import { useTranslation } from 'react-i18next'
import useCurrentDate from '../hooks/useCurrentDate'
import usePracticeDayMap from '../hooks/usePracticeDayMap'
import { E } from '../pixel'

export type onTaskDone = (e: any, task: PracticeTaskOutputDto) => void

export interface TaskViewProps {
  _task: PracticeTaskOutputDto
  onTaskDone: onTaskDone
}

export default function TaskView({ _task, onTaskDone }: TaskViewProps) {
  const { t } = useTranslation()
  // isTask: true인 경우 _task가 곧 정보이고,
  // !isTask인 경우 task는 원본 routine이며 taskToCreate는 routine을 바탕으로 생성할 task data이다.
  const [task, setTask] = useState<PracticeTaskOutputDto>(_task)
  const [currentDate] = useCurrentDate()
  const [practiceDayMap, refreshPracticeDay] = usePracticeDayMap()
  const practiceDay = useMemo(() => {
    return practiceDayMap.get(currentDate)
  }, [practiceDayMap, currentDate])
  const [isModified, setIsModified] = useState(false)

  const navigation = useNavigate()

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsModified(true)
  }

  if (!practiceDay) {
    return <div>loading...</div>
  }

  // Increment repetition count
  const onClickTask = async (e: MouseEvent<HTMLElement>) => {
    let cnt = task.todo.isDone ? 1 : 0
    if (0 < cnt) {
      cnt = 0
    } else {
      onTaskDone(e, task)
      cnt = 1
    }
    const newTask = {
      ...task,
      todo: {
        ...task.todo,
        isDone: !task.todo.isDone,
      },
    } as PracticeTaskOutputDto
    setTask(newTask)
    if (newTask.todo.isDone) {
      E('CompletePracticeTask', {
        taskId: task.id,
        taskSongId: task.practiceSong?.id,
        taskSongName: task.practiceSong?.title,
        taskTitle: task.title,
      })
    }

    const resp = await repoutil.put(`practiceTasks/${task.id}`, {
      title: newTask.title,
      practiceSongId: newTask.practiceSong?.id,
      todo: newTask.todo,
    })
    refreshPracticeDay(currentDate)
  }

  const onClickDeleteTask = async (e: MouseEvent<SVGGElement>) => {
    e.preventDefault()
    E('DeletePracticeTask', {
      taskId: task.id,
      taskSongId: task.practiceSong?.id,
      taskSongName: task.practiceSong?.title,
      taskTitle: task.title,
    })

    const resp1 = await repoutil.del(`practiceTasks/${task.id}`)
    if (resp1.status >= 300) {
      console.error('Failed to delete task')
      return
    }

    refreshPracticeDay(currentDate)
  }

  const generateSongClickHandler = (songId: number) => {
    return async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault()
      // retrieve song info through api
      const resp = await repoutil.get(`practiceSongs/${songId}`)
      const respData = await resp.json()

      navigation(getUpdateSongPageUrl(), {
        state: {
          practiceSong: respData.data,
        },
      })
    }
  }

  return (
    <Stack
      spacing={1.3}
      paddingTop={1}
      paddingLeft={1.5}
      paddingRight={1.5}
      paddingBottom={1}
      sx={{
        borderRadius: '10px',
        backgroundColor: 'white',
      }}
    >
      <Stack
        spacing={1.3}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box m={'2px'} onClick={onClickTask}>
          <img
            src={
              task.todo.isDone
                ? '/strawberry_icon.png'
                : '/strawberry_unchecked_icon.png'
            }
            width={24}
          />
        </Box>
        <Box flexGrow={1}>
          <Typography
            fontSize={'16px'}
            fontWeight={'bold'}
            fontStyle={{
              color: task.todo.isDone ? GRAY : GRAY_DARKEST,
              textDecoration: task.todo.isDone ? 'line-through' : 'none',
            }}
            onClick={onClickTask}
          >
            {task.practiceTaskCategory === 'SONG'
              ? `${task.practiceSong.title} - ${task.practiceSongArtists
                  .map((artist) => artist.name)
                  .join(', ')}`
              : practiceCategories.find(
                  (category) => category.value === task.practiceTaskCategory,
                )?.label}
          </Typography>

          <div>
            {task.title && (
              <Typography
                fontSize={'16px'}
                fontStyle={{
                  color: task.todo.isDone ? GRAY : GRAY_DARKEST,
                  textDecoration: task.todo.isDone ? 'line-through' : 'none',
                }}
                onClick={onClickTask}
              >
                {task.title}
              </Typography>
            )}
          </div>
          <div>
            {task.practiceTaskCategory === 'SONG' && (
              <Typography
                fontSize={'14px'}
                fontStyle={{
                  textDecoration: 'underline',
                  color: GRAY_DARK,
                }}
                onClick={generateSongClickHandler(task.practiceSong.id)}
              >
                {t('SONG_DETAILS')}
              </Typography>
            )}
          </div>
        </Box>
        <Box alignSelf={'center'}>
          <Stack spacing={0.5}>
            <Delete
              sx={{
                color: '#D9D9D9',
              }}
              onClick={onClickDeleteTask}
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  )
}
