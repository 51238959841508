import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useRef,
} from 'react'
import { styled } from '@mui/material/styles'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Paper,
  Checkbox,
  FormControlLabel,
  formControlLabelClasses,
  iconClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClasses,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
} from '@mui/icons-material'
import AppTitleBar from '../AppTitleBar'
import CalendarView from '../CalendarView'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_800,
} from '../theme/theme'
import PracticeDayView from '../PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { YYYYMMDDDateString } from '../types/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  PracticeTaskCategory,
  PracticeTaskOutputDto,
} from '../types/practiceTask'
import { format, subDays } from 'date-fns'

import { useTranslation } from 'react-i18next'
import usePracticeDayMap from '../hooks/usePracticeDayMap'
import { E } from '../pixel'

// Define props for this page
export interface ImportPracticePageProps {
  currentDate: YYYYMMDDDateString
  practiceDays: PracticeDayDetailedOutputDto[]
}

interface ImportPracticeTasksFormValue {
  praticeTaskIds: Set<number>
}

type PracticeTaskOption =
  | NonSongCategoryPracticeTaskOption
  | SongCategoryPracticeTaskOption

interface NonSongCategoryPracticeTaskOption {
  isSelected: boolean
  id: number
  practiceTaskCategory: 'SONG'
  practiceSongId: number
  title: string
}

interface SongCategoryPracticeTaskOption {
  isSelected: boolean
  id: number
  practiceTaskCategory:
    | 'SONG'
    | 'SCALE'
    | 'IMPROVISATION'
    | 'CHORD'
    | 'RHYTHM'
    | 'ARPPEGIO'
    | 'OTHERS'
  title: string
}

interface PracticeDayOption {
  isSelected: boolean
  date: YYYYMMDDDateString
  practiceTasks: PracticeTaskOption[]
}

const buildPracticeDayOptions = (
  practiceDays: PracticeDayDetailedOutputDto[],
): PracticeDayOption[] => {
  return practiceDays.map((practiceDay) => {
    return {
      isSelected: false,
      date: practiceDay.date,
      practiceTasks: practiceDay.practiceTasks.map((task) => {
        switch (task.task.practiceTaskCategory) {
          case 'SONG':
            return {
              isSelected: false,
              id: task.task.id,
              practiceTaskCategory: 'SONG',
              practiceSongId: task.song?.id ?? -1,
              title: task.song?.title ?? '',
            }
          default:
            return {
              isSelected: false,
              id: task.task.id,
              practiceTaskCategory: task.task.practiceTaskCategory,
              title: task.task.title,
            }
        }
      }),
    } as PracticeDayOption
  })
}

const getTaskCountForDay = (practiceDay: PracticeDayOption): number => {
  return practiceDay.practiceTasks.length
}

const isEmptyDay = (practiceDay: PracticeDayOption): boolean =>
  getTaskCountForDay(practiceDay) == 0

const getSelectedPracticeTaskIds = (practiceDayOptions: PracticeDayOption[]) =>
  practiceDayOptions.reduce((acc: number[], practiceDay) => {
    const taskIds = practiceDay.practiceTasks
      .filter((task) => task.isSelected)
      .map((task) => task.id)
    acc.push(...taskIds)
    return acc
  }, [])

export interface ImportPracticePageProps {}

export default function ImportPracticePage() {
  const { t } = useTranslation()
  const [practiceDayMap, refreshPracticeDay] = usePracticeDayMap()

  const location = useLocation()
  const navigate = useNavigate()
  const { handleSubmit, control } = useForm<ImportPracticeTasksFormValue>()
  const topNavRef = useRef<HTMLDivElement>(null)
  const submitButtonRef = useRef<HTMLInputElement>(null)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const [topNavHeight, setTopNavHeight] = useState<number>(0)
  useEffect(() => {
    const calculateContentHeight = () => {
      const topNavHeight = topNavRef.current?.offsetHeight || 0
      // const bottomNavHeight = bottomNavRef.current?.offsetHeight || 0;
      const bottomNavHeight = 0
      const availableHeight =
        window.innerHeight - topNavHeight - bottomNavHeight

      setContentHeight(availableHeight)
      setTopNavHeight(topNavHeight)
    }

    calculateContentHeight() // Initial calculation

    // Recalculate when the window is resized
    window.addEventListener('resize', calculateContentHeight)

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', calculateContentHeight)
  }, [])

  const [search] = useSearchParams()
  const [currentDate, setCurrentDate] = useState<
    YYYYMMDDDateString | undefined
  >()

  useEffect(() => {
    const val = search.get('date')
    if (val) {
      setCurrentDate(val)
      return
    }

    setCurrentDate(undefined)
  }, [search])

  const [practiceDayDetails, setPracticeDayDetails] = useState<
    PracticeDayDetailedOutputDto[]
  >([])

  const fetchPracticeDayDetails = async (): Promise<
    PracticeDayDetailedOutputDto[]
  > => {
    if (!currentDate) {
      return []
    }

    const startDate = new Date(currentDate)
    const dates: string[] = []

    for (let i = 0; i < 14; i++) {
      const cur = subDays(startDate, i)
      // Timezone을 전달해야함...
      // 여기서는 8/15라고 보냈을 때 서버는 UTC 8/15로 간주하는데, 9시간 빼서 계산하다보니 8/14로 계산되는 경우 존재.
      dates.push(format(cur, 'yyyy-MM-dd'))
    }

    try {
      const promises = dates.map((date) => repoutil.retrievePracticeDay(date))
      const results = await Promise.all(promises)
      const practiceDayDetails = results.map((practiceDay, i) => {
        return practiceDay
      })

      return practiceDayDetails
    } catch (err) {
      console.error('Error fetching practice days: ', err)
    }

    return []
  }

  useEffect(() => {
    ;(async () => {
      const result = await fetchPracticeDayDetails()
      setPracticeDayDetails(result)
      setPracticeDayOptions(buildPracticeDayOptions(result))
    })()
  }, [currentDate])

  const [practiceDayOptions, setPracticeDayOptions] = useState<
    PracticeDayOption[]
  >(buildPracticeDayOptions(practiceDayDetails))

  const generateClickPracticeTaskHandler = (
    practiceDay: PracticeDayOption,
    practiceTask: PracticeTaskOption,
  ) => {
    return (e: SyntheticEvent) => {
      // shallow copy 하므로 주의.
      practiceTask.isSelected = !practiceTask.isSelected

      const isSelectedAll = practiceDay.practiceTasks.every(
        (task) => task.isSelected,
      )
      practiceDay.isSelected = isSelectedAll

      setPracticeDayOptions([...practiceDayOptions])
    }
  }

  const generateClickPracticeDayHandler = (practiceDay: PracticeDayOption) => {
    return (e: SyntheticEvent) => {
      practiceDay.isSelected = !practiceDay.isSelected
      practiceDay.practiceTasks.forEach((task) => {
        task.isSelected = practiceDay.isSelected
      })

      setPracticeDayOptions([...practiceDayOptions])
    }
  }

  const onClickButton = async (e: SyntheticEvent) => {
    E('ImportPracticeTasks', {})
    if (!currentDate) {
      alert(`Invalid currentDate:${currentDate}`)
      return
    }
    const resp = await repoutil.post(`practiceDays/${currentDate}/import`, {
      practiceTaskIds: getSelectedPracticeTaskIds(practiceDayOptions),
    })

    if (!resp.ok) {
      console.error('Error importing practice: ', resp)
      return
    }
    const data = await resp.json()
    console.log(data)

    refreshPracticeDay(currentDate)
    navigate(-1)
  }
  const onClickBackButton = (e: SyntheticEvent) => {
    navigate(-1)
  }

  return (
    <Box
      height="100vh"
      sx={{
        display: 'flex',
        backgroundColor: GRAY_LIGHTEST,
      }}
    >
      <div
        ref={topNavRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <AppTitleBar
          title={t('IMPORT_PRACTICE')}
          titleCenter={true}
          hideLogo={true}
          leftCornerNode={
            <IconButton
              edge="start"
              aria-label="back"
              onClick={onClickBackButton}
            >
              <ArrowBackIosOutlined sx={{ color: GRAY }} />
            </IconButton>
          }
        />
      </div>

      <Stack
        id="content"
        spacing={1.5}
        overflow={'scroll'}
        width="100%"
        height={`${contentHeight}px`}
        marginTop={`${topNavHeight}px`}
      >
        <Stack p={2} spacing={1}>
          {practiceDayOptions.map((practiceDay) => (
            <Accordion
              key={practiceDay.date}
              elevation={0}
              disableGutters={true}
              sx={{
                paddingY: '6px',
                [`&:before`]: {
                  display: 'none',
                },
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                ':first-of-type': {
                  borderRadius: '10px',
                },
                ':last-of-type': {
                  borderRadius: '10px',
                },
              }}
            >
              <AccordionSummary
                disabled={isEmptyDay(practiceDay)}
                expandIcon={
                  <ExpandMore
                    sx={{
                      color: GRAY,
                    }}
                  />
                }
                sx={{
                  paddingY: 0,
                  paddingX: '4px',
                  [`& .${accordionSummaryClasses.content}`]: {
                    margin: '0px',
                  },
                  [`&.${accordionSummaryClasses.expanded}`]: {
                    [`& .${accordionSummaryClasses.content}`]: {
                      margin: '0px',
                    },
                  },
                }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  px={1}
                  sx={{ width: '100%' }}
                >
                  <FormControlLabel
                    label={practiceDay.date}
                    sx={{
                      flexGrow: '1',
                      [`& .${formControlLabelClasses.label}`]: {
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: practiceDay.isSelected
                          ? GREEN_800
                          : GRAY_DARKEST,
                      },
                    }}
                    control={
                      <Checkbox
                        checked={practiceDay.isSelected}
                        onChange={generateClickPracticeDayHandler(practiceDay)}
                      />
                    }
                  ></FormControlLabel>
                  <Typography color={GRAY}>
                    {practiceDay.practiceTasks.length}
                    {` ${t('UNIT_PRACTICE')}`}
                  </Typography>
                </Box>
              </AccordionSummary>
              {!isEmptyDay(practiceDay) && (
                <>
                  <AccordionDetails
                    sx={{
                      paddingX: '0px',
                      paddingTop: '0px',
                      paddingBottom: '12px',
                    }}
                  >
                    {practiceDay.practiceTasks.map((practiceTask, i) => (
                      <Stack key={`${i}`} px={5}>
                        <Typography color={GRAY}>
                          {practiceTask.title}
                        </Typography>
                        <FormControlLabel
                          key={practiceTask.id}
                          label={practiceTask.title}
                          sx={{
                            flexGrow: '1',
                            [`& .${formControlLabelClasses.label}`]: {
                              fontSize: '16px',
                              marginTop: '4px',
                              marginBottom: '2px',
                              color: practiceTask.isSelected
                                ? GREEN_800
                                : GRAY_DARKEST,
                            },
                          }}
                          control={
                            <Checkbox
                              size="small"
                              checked={practiceTask.isSelected}
                              onChange={generateClickPracticeTaskHandler(
                                practiceDay,
                                practiceTask,
                              )}
                              sx={{ paddingY: '0px', paddingX: '9px' }}
                            />
                          }
                        ></FormControlLabel>
                      </Stack>
                    ))}
                  </AccordionDetails>
                </>
              )}
            </Accordion>
          ))}
        </Stack>
      </Stack>
      <Button
        fullWidth
        size={'large'}
        variant="contained"
        onClick={onClickButton}
        sx={{
          position: 'absolute',
          fontSize: '16px',
          width: '70vw',
          backgroundColor: GREEN,
          left: '15vw',
          bottom: '32px;',
        }}
      >
        Import
      </Button>
    </Box>
  )
}
