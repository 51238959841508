import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useRef,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Paper,
  Checkbox,
  FormControlLabel,
  formControlLabelClasses,
  iconClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClasses,
  TextField,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
} from '@mui/icons-material'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import * as timeutil from './util/timeutil'

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_800,
} from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import { YYYYMMDDDateString } from './types/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  PracticeTaskCategory,
  PracticeTaskOutputDto,
} from './types/practiceTask'
import { format, subDays } from 'date-fns'
import { GradientButton, OutlinedButton } from './Components/Button'
import { z } from 'zod'
import BasePage from './BasePage'
import useCurrentDate from './hooks/useCurrentDate'
import usePracticeDayMap from './hooks/usePracticeDayMap'
import { E } from './pixel'

// Define props for this page
export interface WritePostPageProps {}

const CreatePracticeNoteFormData = z.object({
  content: z.string({
    message: 'Please input content',
  }),
})

export type CreatePracticeNoteFormData = z.infer<
  typeof CreatePracticeNoteFormData
>

export default function WritePostPage({}: WritePostPageProps) {
  const [currentDate] = useCurrentDate()
  const [practiceDayMap, refreshPracticeDay] = usePracticeDayMap()
  const practiceDay = useMemo(() => {
    return practiceDayMap.get(currentDate)
  }, [practiceDayMap, currentDate])
  const navigate = useNavigate()
  const contentRef = useRef<HTMLInputElement>(null)
  const emojis = ['🎯', '🔥', '🚀', '🎉', '😃', '🥲', '👍', '👎']
  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
  } = useForm<CreatePracticeNoteFormData>({ defaultValues: { content: '' } })

  const onSubmit = async (formData: CreatePracticeNoteFormData) => {
    E('CreatePost', {})
    const resp = await repoutil.post(`practiceNotes`, {
      ...formData,
      date: currentDate,
    })
    const data = await resp.json()
    console.log(data)

    if (!practiceDay) return

    refreshPracticeDay(currentDate)
    navigate(-1)
  }
  const generateEmojiClickHandler = (emoji: string) => {
    return (e: SyntheticEvent) => {
      const inputElement = contentRef.current
      const start = inputElement?.selectionStart || 0
      const end = inputElement?.selectionEnd || 0
      const inputValue = inputElement?.value || ''
      const newValue = `${inputValue.slice(0, start)}${emoji}${inputValue.slice(
        end,
      )}`
      setValue('content', newValue)
      inputElement?.focus()
      // @ts-ignore
      inputElement.selectionStart = start + emoji.length
      // @ts-ignore
      inputElement.selectionEnd = start + emoji.length

      e.preventDefault()
    }
  }

  const onClickBackButton = () => {
    navigate(-1)
  }
  const onClickSubmitButton = () => {
    handleSubmit(onSubmit)()
  }

  return (
    <BasePage
      appTitleBarProps={{
        leftCornerNode: (
          <IconButton
            edge="start"
            aria-label="back"
            onClick={onClickBackButton}
          >
            <ArrowBackIosOutlined sx={{ color: GRAY }} />
          </IconButton>
        ),
        rightCornerNode: (
          <Typography color={GRAY_DARK} onClick={onClickSubmitButton}>
            Save
          </Typography>
        ),
        title: 'Write',
        titleCenter: true,
        hideLogo: true,
      }}
      child={
        <form style={{ width: '100%' }}>
          <Grid
            container
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
          >
            <Grid xs={12}>
              <Stack pt={1} direction={'row'} justifyContent={'space-evenly'}>
                {emojis.map((emoji) => (
                  <Typography
                    fontSize="24px"
                    onClick={generateEmojiClickHandler(emoji)}
                  >
                    {emoji}
                  </Typography>
                ))}
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    minRows={4}
                    fullWidth
                    inputRef={contentRef}
                    sx={{
                      backgroundColor: 'white',
                      '& fieldset': { border: 'none' },
                    }}
                    variant="outlined"
                    placeholder="Enter your note here"
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      }
    />
  )
}
