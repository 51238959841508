import { SetterOrUpdater, useRecoilState } from 'recoil'
import { practiceDayMapState } from '../state/practiceday'
import { useEffect, useState } from 'react'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { getDateRange } from '../util/dateutil'
import { YYYYMMDDDateString } from '../types/common'
import { retrievePracticeDay, retrievePracticeDays } from '../util/repoutil'

// Declare refreshPracticeDay function as a Type
type PracticeDayRefresher = (dateString: YYYYMMDDDateString) => void

export default function usePracticeDayMap(): [
  Map<YYYYMMDDDateString, PracticeDayDetailedOutputDto>,
  PracticeDayRefresher,
] {
  const [dates] = useState<Array<YYYYMMDDDateString>>(
    getDateRange().map(
      (date) => date.toISOString().split('T')[0] as YYYYMMDDDateString,
    ),
  )

  const [practiceDayMap, setPracticeDayMap] =
    useRecoilState(practiceDayMapState)

  const refreshPracticeDay = async (date: YYYYMMDDDateString) => {
    const practiceDay = await retrievePracticeDay(date)
    setPracticeDayMap((prev) => new Map(prev.set(date, practiceDay)))
  }

  const refreshPracticeDays = async () => {
    const practiceDays = await retrievePracticeDays(dates)
    const newMap = new Map<YYYYMMDDDateString, PracticeDayDetailedOutputDto>()
    practiceDays.forEach((practiceDay) => {
      newMap.set(practiceDay.date, practiceDay)
    })
    setPracticeDayMap(newMap)
  }

  useEffect(() => {
    refreshPracticeDays()
  }, [])

  return [practiceDayMap, refreshPracticeDay]
}
