import React, { useState, useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
} from '@mui/icons-material'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import SettingsCategoryHeader from './SettingsCategoryHeader'
import SettingsItemButton from './SettingItemButton'
import { GrayTextButtonSmall } from '../Components/Button'
import { textAlign } from '@mui/system'
import { GRAY_LIGHTEST } from '../theme/theme'
import { useTranslation } from 'react-i18next'

const TERMS_OF_SERVICVE_URL = process.env.REACT_APP_TERMS_OF_SERVICVE_URL
const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL
const CUSTOMER_SERVICE_URL = process.env.REACT_APP_CUSTOMER_SERVICE_URL
const FEEDBACK_URL = process.env.REACT_APP_FEEDBACK_URL
export default function SettingsPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onSignOut = () => {
    repoutil.deleteAuthentication()
    window.location.replace(`/signin`)
  }
  const onDeleteAccount = async () => {
    const resp = await repoutil.del('users/me')
    const data = await resp.json()
    if (resp.status != 200) {
      console.error(data)
      return
    }
    repoutil.deleteAuthentication()
    window.location.replace(`/signin`)
  }

  const username = repoutil.getUserInfo().username
  return (
    <Box sx={{ backgroundColor: GRAY_LIGHTEST, height: '100vh' }}>
      <Stack py={2} spacing={2}>
        <Box>
          <SettingsCategoryHeader value={t('ACCOUNT')} subHeader={username} />
          <Stack
            spacing={1.3}
            sx={{ backgroundColor: '#FFFFFF' }}
            py={2}
            divider={
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ color: '#F9F9F9' }}
              />
            }
          >
            <SettingsItemButton onClick={onSignOut} value={t('SIGN_OUT')} />
            <SettingsItemButton
              onClick={onDeleteAccount}
              value={t('DELETE_ACCOUNT')}
            />
          </Stack>
        </Box>
        <Box>
          <SettingsCategoryHeader value={t('DEVELOPMENT')} />
          <Stack
            spacing={1.3}
            sx={{ backgroundColor: '#FFFFFF' }}
            py={1.3}
            divider={
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ color: '#F9F9F9' }}
              />
            }
          >
            <SettingsItemButton
              onClick={() => window.open(FEEDBACK_URL, '_blank')}
              variant="primary"
              value={`${t('SEND_FEEDBACK')} 🥰`}
            />
            <SettingsItemButton
              onClick={{}}
              variant="disabled"
              value={t('DEVELOPMENT_STATE_AND_ROADMAPS')}
            />
          </Stack>
        </Box>
        <Box>
          <SettingsCategoryHeader value={t('OTHERS')} />
          <Stack
            spacing={1.3}
            sx={{ backgroundColor: '#FFFFFF' }}
            py={2}
            divider={
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ color: '#F9F9F9' }}
              />
            }
          >
            <SettingsItemButton
              onClick={() => window.open(TERMS_OF_SERVICVE_URL, '_blank')}
              value={t('TERMS_OF_SERVICE')}
            />
            <SettingsItemButton
              onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
              value={t('PRIVACY_POLICY')}
            />
            <SettingsItemButton
              onClick={() => window.open(CUSTOMER_SERVICE_URL, '_blank')}
              value={t('CUSTOMER_SERVICE')}
            />
          </Stack>
        </Box>
        <Box pl="24px">
          <Typography fontSize="12px" color="#D9D9D9">
            <i>Options in gray are not supported yet.</i>
          </Typography>
        </Box>
      </Stack>
      <CustomBottomNavigation />
    </Box>
  )
}
