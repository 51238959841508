import * as jjwt from 'jsonwebtoken'
import { YYYYMMDDDateString } from '../types/common'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { languageCode } from './langutil'
import * as amplitude from '@amplitude/analytics-browser'

const API_HOST = process.env.REACT_APP_API_SERVER_URL

const defaultHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAccessToken()}`,
})

export function saveAuthentication(accessToken: string) {
  localStorage.setItem('accessToken', accessToken)
  const jwt = jjwt.decode(accessToken, { json: true })
  if (jwt == null) {
    console.warn('JWT object is null')
    return
  }
  amplitude.setUserId(jwt['sub'])
  localStorage.setItem('username', jwt['sub'] ?? '')
  localStorage.setItem('nickname', jwt['nickname'])
  localStorage.setItem('i18nextLng', languageCode(jwt['language']))

  return
}
export function deleteAuthentication() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('username')
  localStorage.removeItem('nickname')

  return
}

export function getAccessToken() {
  return localStorage.getItem('accessToken')
}

export function getUserInfo() {
  return {
    username: localStorage.getItem('username'),
    nickname: localStorage.getItem('nickname'),
  }
}

export function isAuthenticated() {
  const token = getAccessToken()
  if (token == undefined) {
    console.warn('failed to get token')
    return false
  }
  const jwt = jjwt.decode(token, { json: true })
  if (jwt == null) {
    console.warn('JWT object is null')
    return false
  }
  const currentTimestamp = Math.floor(Date.now() / 1000) // Current time in seconds
  if (jwt.exp ? jwt.exp < currentTimestamp : true) {
    console.warn(`JWT is expired. current=${currentTimestamp}, exp=${jwt.exp}`)
    return false
  }

  return true
}
export function http(
  endpoint: string,
  method = 'GET',
  headers = {},
  body: any = null,
) {
  const options: any = {
    method: method,
    headers: { ...defaultHeaders(), ...headers },
  }

  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
    if (options['headers']['Content-Type'] == 'application/json') {
      options['body'] = JSON.stringify(body)
    } else {
      if (options['headers']['Content-Type'] == 'multipart/form-data') {
        delete options['headers']['Content-Type']
      }
      options['body'] = body
    }
  }

  return fetch(API_HOST + endpoint, options)
}

export function post(endpoint: string, body: any): any {
  return http(endpoint, 'POST', {}, body)
}
export function put(endpoint: string, body: any): any {
  return http(endpoint, 'PUT', {}, body)
}
export function patch(endpoint: string, body: any): any {
  return http(endpoint, 'PATCH', {}, body)
}
export function del(endpoint: string, body: any = null): any {
  return http(endpoint, 'DELETE', {}, body)
}
export function get(endpoint: string): any {
  return http(endpoint, 'GET', {}, {})
}

export function http2(
  endpoint: string,
  method = 'GET',
  headers = {},
  body: any = null,
) {
  const options: any = {
    method: method,
    headers: { ...defaultHeaders(), ...headers },
  }

  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
    if (options['headers']['Content-Type'] == 'application/json') {
      options['body'] = JSON.stringify(body)
    } else {
      if (options['headers']['Content-Type'] == 'multipart/form-data') {
        delete options['headers']['Content-Type']
      }
      options['body'] = body
    }
  }

  return fetch(API_HOST + endpoint, options)
}

// export async function post2(endpoint: string, body: any) {
//   return http(endpoint, 'POST', {}, body)
// }
// export async function put2(endpoint: string, body: any) {
//   return http(endpoint, 'PUT', {}, body)
// }
// export async function patch2(endpoint: string, body: any) {
//   return http(endpoint, 'PATCH', {}, body)
// }
// export async function del2(endpoint: string, body: any) {
//   return http(endpoint, 'DELETE', {}, body)
// }
// export async function get2(endpoint: string) {
//   return http(endpoint, 'GET', {}, {})
// }

export const retrievePracticeDay = async (
  dateString: YYYYMMDDDateString,
): Promise<PracticeDayDetailedOutputDto> => {
  const resp = await http(`practiceDays/${dateString}`)
  if (!resp.ok) {
    console.error(resp.status)
    return {} as PracticeDayDetailedOutputDto
  }

  const data = await resp.json()
  if (data['error']) {
    console.error(data['error'])
  }

  console.log(data)
  return data.data as PracticeDayDetailedOutputDto
}

export const retrievePracticeDays = async (
  dates: Array<YYYYMMDDDateString>,
): Promise<Map<YYYYMMDDDateString, PracticeDayDetailedOutputDto>> => {
  if (!getAccessToken()) {
    return Promise.resolve(new Map())
  }

  const promises = dates.map((date) => retrievePracticeDay(date))
  const results = await Promise.all(promises)
  const practiceDayMap = new Map()
  results.forEach((practiceDay, i) => {
    practiceDayMap.set(dates[i], practiceDay)
  })
  return practiceDayMap
}
