import { SongKind } from '../types/practiceSong'
import { z } from 'zod'

export const PracticeTaskCategory = z.enum([
  'SONG',
  'SCALE',
  'IMPROVISATION',
  'CHORD',
  'RHYTHM',
  'OTHERS',
])

export const practiceCategories = [
  {
    label: '스케일',
    value: PracticeTaskCategory.Enum.SCALE,
  },
  {
    label: '즉흥연주',
    value: PracticeTaskCategory.Enum.IMPROVISATION,
  },
  {
    label: '코드',
    value: PracticeTaskCategory.Enum.CHORD,
  },
  {
    label: '리듬',
    value: PracticeTaskCategory.Enum.RHYTHM,
  },
  {
    label: '기타',
    value: PracticeTaskCategory.Enum.OTHERS,
  },
]

export type PracticeTaskCategory = z.infer<typeof PracticeTaskCategory>

export type TodoPracticeAchievementType = 'REPEAT' | 'TODO'

export const SongDefiningMethod = z.enum(['CREATE', 'CHOOSE'])
export type SongDefiningMethod = z.infer<typeof SongDefiningMethod>

export const CreateSongPracticeTaskFormDataSchema = z.object({
  practiceSongId: z.number({
    message: 'Please select a song',
  }),
  title: z.string().default(''),
})

export type CreateSongPracticeTaskFormData = z.infer<
  typeof CreateSongPracticeTaskFormDataSchema
>

export const CreateNonSongPracticeTaskFormDataSchema = z.object({
  title: z.string().default(''),
  practiceTaskCategory: PracticeTaskCategory,
})

export type CreateNonSongPracticeTaskFormData = z.infer<
  typeof CreateNonSongPracticeTaskFormDataSchema
>

export const CreateSongFormDataSchema = z.object({
  title: z.string({ message: 'Please enter a title' }),
  artist: z.string({ message: 'Please enter an artist' }),
})

export type CreateSongFormData = z.infer<typeof CreateSongFormDataSchema>
