import React, { useEffect } from 'react'

export default function NaverLoginButton() {
  const { naver, location }: any = window
  const NAVER_CLIENT_ID = '8501ARSmXPpnamqgFK6E' // 발급 받은 Client ID 입력
  // const NAVER_CALLBACK_URL = `${process.env.REACT_APP_API_SERVER_URL}auth/naver/callback`
  const NAVER_CALLBACK_URL = `${location.origin}/signin/naver/callback`

  // 네이버 로그인 기능 및 버튼 구현
  const naverLogin = new naver.LoginWithNaverId({
    clientId: NAVER_CLIENT_ID, // Naver Developer 에 있는 Client ID
    callbackUrl: NAVER_CALLBACK_URL, // 요청 보냈을때 네이버에서 응답해 줄 주소
    isPopup: false, // 네이버 로그인 확인 창을 팝업으로 띄울지 여부
    loginButton: {
      color: 'green', // green, white
      type: 3, // 1: 작은버튼, 2: 중간버튼, 3: 큰 버튼
    },
    callbackHandle: false,
  })

  useEffect(() => {
    naverLogin.init()
  }, [])

  return (
    <>
      <div id="naverIdLogin"></div>
    </>
  )
}
