import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import * as dateutil from '../util/dateutil'
import * as repoutil from '../util/repoutil'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { YYYYMMDDDateString } from '../types/common'

// @Deprecated. Use practiceDayMapState instead.
// 현재 PracticeDayState를 사용하는 경우에만 설정
// export const currentPracticeDayState = atom<
//   PracticeDayDetailedOutputDto | undefined
// >({
//   key: 'currentPracticeDay',
//   default: undefined,
// })

// // @Deprecated. Use practiceDayMapState instead.
// // 앱 접속 시 한 번 설정,
// // currentPracticeDayState를 set할 때 currentPracticeDayState.date==todayPracticeDayState.date인 경우에도 설정
// export const todayPracticeDayState = atom({
//   key: 'todayPracticeDay',
//   default: await repoutil.retrievePracticeDay(dateutil.formatDate(new Date())),
// })

export const currentDateState = atom<YYYYMMDDDateString>({
  key: 'currentDate',
  default: dateutil.nowYYYYMMDD(),
})

// practiceDayMapState is a map.
// key is YYYYMMDD string and value is PracticeDayDetailedOutputDto
export const practiceDayMapState = atom<
  Map<YYYYMMDDDateString, PracticeDayDetailedOutputDto>
>({
  key: 'practiceDayMap',
  default: new Map(),
})
