import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useRef,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Paper,
  Checkbox,
  FormControlLabel,
  formControlLabelClasses,
  iconClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClasses,
  StackOwnProps,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
} from '@mui/icons-material'
import AppTitleBar, { AppTitleBarProps } from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import * as timeutil from './util/timeutil'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_800,
} from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import { YYYYMMDDDateString } from './types/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  PracticeTaskCategory,
  PracticeTaskOutputDto,
} from './types/practiceTask'
import { format, subDays } from 'date-fns'
import { GradientButton, OutlinedButton } from './Components/Button'
import { PracticeNoteOutputDto } from './types/practiceNote'
import PracticeNoteListView, {
  PracticeNoteListQueryType,
} from './PracticeNoteListView'
import { BoxOwnProps } from '@mui/system'

// Define props for this page
export interface BasePageProps {
  child: JSX.Element
  boxProps?: BoxOwnProps
  stackProps?: StackOwnProps
  appTitleBarProps?: AppTitleBarProps
}

export default function BasePage({
  child,
  boxProps,
  stackProps,
  appTitleBarProps,
}: BasePageProps) {
  const navigate = useNavigate()
  const topNavRef = useRef<HTMLDivElement>(null)
  const bottomNavRef = useRef<HTMLDivElement | undefined>()
  const [contentHeight, setContentHeight] = useState<number>(0)
  const [topNavHeight, setTopNavHeight] = useState<number>(0)
  useEffect(() => {
    const calculateContentHeight = () => {
      const topNavHeight = topNavRef.current?.offsetHeight || 0
      const bottomNavHeight = bottomNavRef.current?.offsetHeight || 0
      const availableHeight =
        window.innerHeight - topNavHeight - bottomNavHeight

      setContentHeight(availableHeight)
      setTopNavHeight(topNavHeight)
    }

    calculateContentHeight() // Initial calculation

    // Recalculate when the window is resized
    window.addEventListener('resize', calculateContentHeight)

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', calculateContentHeight)
  }, [])
  return (
    <Box
      height="100vh"
      sx={{
        display: 'flex',
        backgroundColor: GRAY_LIGHTEST,
      }}
      {...boxProps}
    >
      <div
        ref={topNavRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <AppTitleBar {...appTitleBarProps} />
      </div>

      <Stack
        id="content"
        spacing={2}
        overflow={'scroll'}
        width="100%"
        height={`${contentHeight}px`}
        marginTop={`${topNavHeight}px`}
        p={2}
        {...stackProps}
      >
        {child}
      </Stack>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <CustomBottomNavigation ref={bottomNavRef} />
      </div>
    </Box>
  )
}
