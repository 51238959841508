import React, {
  Fragment,
  useState,
  useEffect,
  ChangeEvent,
  SyntheticEvent,
} from 'react'
import { styled, ThemeProvider } from '@mui/material/styles'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Backdrop,
  LinearProgress,
  Link,
  TextField,
  Container,
  Button,
  FormControlLabel,
  FormControl,
  FormGroup,
  InputLabel,
  Checkbox,
  MenuItem,
  Select,
  ButtonGroup,
  SelectChangeEvent,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  CheckBox,
} from '@mui/icons-material'
import AppleLogin from 'react-apple-login'

import * as userutil from '../util/userutil'
import * as repoutil from '../util/repoutil'
import { GRAY, GRAY_DARK } from '../theme/theme'
import AppTitleBar from '../AppTitleBar'
import { GradientButton } from '../Components/Button'
import { useTranslation } from 'react-i18next'
import { languageCode, languageName } from '../util/langutil'
import i18next from 'i18next'
import {
  AppleSignInInputDto,
  AppleSignInOutputDto,
  IssueTokenInputDto,
  IssueTokenOutputDto,
  NaverSignInInputDto,
  NaverSignInOutputDto,
} from '../types/appUser'
import { getAppleSignInCallback, getHomeUrl } from '../config/config'

const TERMS_OF_SERVICVE_URL = process.env.REACT_APP_TERMS_OF_SERVICVE_URL
const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL

const consentBoxStackProps = {
  direction: 'row' as const,
  display: 'flex' as const,
  alignItems: 'center',
  width: '100%',
  color: 'gray',
}

const consentAnchorProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

export interface SignUpInputData {
  username: string
  password: string
  language: string
  appleIdToken: string
  naverAccessToken: string
  consentToServiceTerms: boolean
  consentToPrivacyPolicy: boolean
  instruments: string[]
  genres: string[]
}
export default function SignUpPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const qs = location.search
  const params = new URLSearchParams(qs)
  const appleIdToken = params.get('appleIdToken')
  const naverAccessToken = params.get('naverAccessToken')
  const preferredUsername = params.get('preferredUsername')
  const isSocialLoggedin =
    (appleIdToken && appleIdToken.length > 0) ||
    (naverAccessToken && naverAccessToken.length > 0)

  const languages = [
    ['ENGLISH', 'English'],
    ['KOREAN', '한글'],
    // ['CHINESE', '中文'],
    // ['JAPANESE', '日本語'],
    ['SPANISH', 'Español'],
    ['FRENCH', 'Français'],
    ['ITALIANO', 'Italiano'],
    // ['PORTUGUESE', 'Português'],
  ]

  const [inputData, setInputData] = useState<SignUpInputData>({
    username: preferredUsername ? preferredUsername : '',
    password: '',
    language: languageName(i18next.language),
    appleIdToken: appleIdToken ? appleIdToken : '',
    naverAccessToken: naverAccessToken ? naverAccessToken : '',
    consentToServiceTerms: false,
    consentToPrivacyPolicy: false,
    instruments: [],
    genres: [],
  })
  const navigate = useNavigate()
  const generateChangeHandler = (key: 'username' | 'password') => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      inputData[key] = event.target.value
      setInputData({ ...inputData })
    }
  }
  const onChangeLanguage = (event: SelectChangeEvent<string>) => {
    const languageName = event.target.value
    setInputData({ ...inputData, language: languageName })
    i18next.changeLanguage(languageCode(languageName))
  }

  const generateCheckboxChangeHandler = (
    key: 'consentToServiceTerms' | 'consentToPrivacyPolicy',
  ) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      inputData[key] = event.target.checked
      setInputData({ ...inputData })
    }
  }

  const createUser = async () => {
    const resp = await repoutil.post('users', inputData)
    const data = await resp.json()
    return data
  }

  const signIn = async () => {
    let accessToken: string = ''
    if (naverAccessToken) {
      accessToken = await getAccessTokenByNaver()
    } else if (appleIdToken) {
      accessToken = await getAccessTokenByApple()
    } else {
      accessToken = await getAccessTokenByUsernameAndPassword()
    }

    repoutil.saveAuthentication(accessToken)
    navigate(getHomeUrl(), { replace: true })
  }

  const getAccessTokenByNaver = async () => {
    const resp = await repoutil.post('auth/naver/callback', {
      naverAccessToken: naverAccessToken,
    } as NaverSignInInputDto)
    const data = (await resp.json()).data as NaverSignInOutputDto
    return data.accessToken
  }

  const getAccessTokenByApple = async () => {
    const resp = await repoutil.post('auth/apple/issue', {
      appleIdToken: appleIdToken,
    } as AppleSignInInputDto)
    const data = (await resp.json()).data as AppleSignInOutputDto
    return data.accessToken
  }

  const getAccessTokenByUsernameAndPassword = async () => {
    const resp = await repoutil.post('auth/issue', {
      username: inputData.username,
      password: inputData.password,
    } as IssueTokenInputDto)
    const data = (await resp.json()).data as IssueTokenOutputDto
    return data.accessToken
  }

  const onSubmit = async (e: SyntheticEvent) => {
    // page 이동해버리려는 케이스 방지
    e.preventDefault()
    await createUser()
    await signIn()
  }

  const onClickBackButton = () => {
    navigate(-1)
  }

  const disabled = () =>
    inputData['username'].length == 0 ||
    inputData['consentToPrivacyPolicy'] == false ||
    inputData['consentToServiceTerms'] == false ||
    (!isSocialLoggedin && inputData['password'].length == 0)

  return (
    <Box>
      <AppTitleBar
        leftCornerNode={
          <IconButton
            edge="start"
            aria-label="back"
            onClick={onClickBackButton}
          >
            <ArrowBackIosOutlined sx={{ color: GRAY }} />
          </IconButton>
        }
        title={t('SIGN_UP')}
        titleCenter={true}
        hideLogo={true}
      />
      <Container>
        <Stack
          direction={'column'}
          spacing={1.5}
          justifyContent={'center'}
          alignItems={'center'}
          m={2}
        >
          <TextField
            id="username"
            label="ID"
            fullWidth
            inputProps={{
              style: {
                fontSize: '12px',
              },
            }}
            value={inputData.username}
            onChange={generateChangeHandler('username')}
          />
          {isSocialLoggedin ? null : (
            <TextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              inputProps={{
                style: {
                  fontSize: '12px',
                },
              }}
              onChange={generateChangeHandler('password')}
            />
          )}
          <FormControl fullWidth>
            <InputLabel id="preferredLanguage">
              {t('PREFERRED_LANGUAGE')}
            </InputLabel>
            <Select
              labelId="preferredLanguage"
              id="language"
              value={inputData.language}
              aria-label={t('PREFERRED_LANGUAGE')}
              label={t('PREFERRED_LANGUAGE')}
              fullWidth={true}
              onChange={onChangeLanguage}
            >
              {languages.map((language, i) => (
                <MenuItem key={language[0]} value={language[0]}>
                  {language[1]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack {...consentBoxStackProps}>
            <Checkbox
              onChange={generateCheckboxChangeHandler('consentToServiceTerms')}
            />
            <Typography color={GRAY_DARK}>
              {t('TERMS_OF_SERVICE_AGREEMENT')} (
              <a {...consentAnchorProps} href={TERMS_OF_SERVICVE_URL}>
                link
              </a>
              )
            </Typography>
          </Stack>
          <Stack {...consentBoxStackProps}>
            <Checkbox
              onChange={generateCheckboxChangeHandler('consentToPrivacyPolicy')}
            />
            <Typography color={GRAY_DARK}>
              {t('PRIVACY_POLICY_AGREEMENT')} (
              <a {...consentAnchorProps} href={PRIVACY_POLICY_URL}>
                link
              </a>
              )
            </Typography>
          </Stack>
          {disabled() ? (
            <Button
              variant="contained"
              disableElevation
              fullWidth
              disabled={
                inputData['username'].length == 0 ||
                inputData['consentToPrivacyPolicy'] == false ||
                inputData['consentToServiceTerms'] == false ||
                (!isSocialLoggedin && inputData['password'].length == 0)
              }
              style={{ textTransform: 'none' }}
            >
              {t('SIGN_UP')}
            </Button>
          ) : (
            <GradientButton disableElevation fullWidth onClick={onSubmit}>
              {t('SIGN_UP')}
            </GradientButton>
          )}
        </Stack>
      </Container>
    </Box>
  )
}
