import React, { Fragment, useState, useEffect } from 'react'
import './App.css'
import { Box, Stack, Divider } from '@mui/material'

import { buttonClasses } from '@mui/material/Button'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
  useLocation,
} from 'react-router-dom'
import Home from './Home/Home'
import CalendarView from './CalendarView'
import AppTitleBar from './AppTitleBar'
import FeedPage from './CalendarPage'
import * as dateutil from './util/dateutil'
import * as repoutil from './util/repoutil'
import SignInPage from './OnBoarding/SignInPage'
import SignUpPage from './OnBoarding/SignUpPage'
import SettingsPage from './Settings/SettingsPage'
import AuthenticatedRoute from './AuthenticatedRoute'
import { createTheme } from '@mui/material/styles'
import { styled, ThemeProvider } from '@mui/material/styles'
import UpdateProfilePage from './UpdateProfilePage'
import AppleSignInCallback from './OnBoarding/AppleSignInCallback'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

import { theme } from './theme/theme'
import PracticeSongListPage from './PracticeSong/PracticeSongListPage'
import {
  getSongListPageUrl,
  getCreateSongPageUrl,
  getUpdateSongPageUrl,
  getRedirectUrlAfterAuthentication,
  getHomeUrl,
  getCalendarPageBaseUrl,
  getOnBoardingPracticeSongPageUrl,
  getCreatePracticeTaskPageUrl,
  getPracticePageUrl,
  getAppleSignInCallback,
} from './config/config'
import WritePracticeSongPage from './PracticeSong/WritePracticeSongPage'
import PracticePage from './Practice/PracticePage'
import ImportPracticePage from './Practice/ImportPracticePage'
import HomePage from './HomePage'
import WritePostPage from './WritePostPage'
import EditPostPage from './EditPostPage'
import OnBoardingDaialog from './OnBoardingDialog'
import OnBoardingPracticeSongPage from './OnBoardingPracticeSongPage'
import AddTaskPage from './Practice/AddTaskPage'
import CalendarPage from './CalendarPage'
import i18n from 'i18next'
import { useTranslation, initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resources from './translation/translation'
import NaverSignInCallback from './OnBoarding/NaverSignInCallback'
import ReactPixel from 'react-facebook-pixel'
import EventTracker from './EventTracker'
import * as amplitude from '@amplitude/analytics-browser'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: resources,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
  })
function App() {
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        const facebookPixelId = '1052409699618813'
        ReactPixel.init(facebookPixelId, undefined, {
          autoConfig: true,
          debug: true,
        })
      })

    if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
      const username = repoutil.getUserInfo().username
      amplitude.init(
        process.env.REACT_APP_AMPLITUDE_API_KEY,
        username ? username : undefined,
        {
          autocapture: true,
        },
      )
    }
  }, [])

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router>
          <EventTracker />
          <Routes>
            <Route
              path="/"
              element={
                <AuthenticatedRoute
                  element={
                    <Navigate
                      to={{
                        pathname: getRedirectUrlAfterAuthentication(),
                      }}
                      replace={true}
                    />
                  }
                />
              }
            />
            <Route
              path={getHomeUrl()}
              element={<AuthenticatedRoute element={<HomePage />} />}
            />
            <Route
              path={getOnBoardingPracticeSongPageUrl()}
              element={<OnBoardingPracticeSongPage />}
            />
            <Route
              path="/practice/import"
              element={<AuthenticatedRoute element={<ImportPracticePage />} />}
            />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route
              path={getAppleSignInCallback()}
              element={<AppleSignInCallback />}
            />
            <Route
              path="/signin/naver/callback"
              element={<NaverSignInCallback />}
            />
            <Route
              path="/profile/update"
              element={<AuthenticatedRoute element={<UpdateProfilePage />} />}
            />
            <Route
              path={`/${getCalendarPageBaseUrl()}/:feedUsername`}
              element={<CalendarPage />}
            />
            <Route path={getPracticePageUrl()} element={<PracticePage />} />
            <Route
              path={getCreatePracticeTaskPageUrl()}
              element={<AddTaskPage />}
            />
            <Route path="/posts/write" element={<WritePostPage />} />
            <Route path="/posts/edit" element={<EditPostPage />} />
            <Route
              path={getSongListPageUrl()}
              element={<PracticeSongListPage />}
            />
            <Route
              path={getCreateSongPageUrl()}
              element={<WritePracticeSongPage />}
            />
            <Route
              path={getUpdateSongPageUrl()}
              element={<WritePracticeSongPage />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default App
