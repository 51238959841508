const translations = {
  TODAY_PRACTICE: {
    en: 'Today Practice',
    ko: '오늘의 연습',
  },
  NO_PRACTICE_YET: {
    en: "There's no practice created yet.",
    ko: '아직 생성된 연습이 없습니다.',
  },
  GUIDE_FOR_PRACTICE_CREATION: {
    en: 'Please create a practice at the below.',
    ko: '아래에서 연습을 생성해주세요.',
  },
  ADD_PRACTICE: {
    en: 'Add Practice',
    ko: '연습 추가하기',
  },
  IMPORT_PRACTICE: {
    en: 'Import Practice',
    ko: '연습 가져오기',
  },
  FINISH: {
    en: 'Finish',
    ko: '완료',
  },
  SAVE: {
    en: 'Save',
    ko: '저장',
  },
  ADD: {
    en: 'Add',
    ko: '추가',
  },
  DELETE: {
    en: 'Delete',
    ko: '삭제',
  },
  START_PRACTICE: {
    en: 'Start Practice',
    ko: '연습 시작하기',
  },
  COMPLETED_PRACTICE: {
    en: 'Completed Practice',
    ko: '완료한 연습',
  },
  PRACTICE_DURATION: {
    en: 'Practice Duration',
    ko: '연습한 시간',
  },
  MOVE_TO_PRACTICE: {
    en: 'Move to practice',
    ko: '연습하러 가기',
  },
  TODAY_POSTS: {
    en: "Today's Posts",
    ko: '오늘의 연습노트',
  },
  NO_POSTS_YET: {
    en: "There's no post created yet.",
    ko: '아직 생성된 연습노트가 없습니다.',
  },
  WRITE_POST: {
    en: 'Write a Post',
    ko: '연습노트 쓰기',
  },
  UNIT_POST: {
    en: 'Posts',
    ko: '연습노트',
  },
  UNIT_SONG: {
    en: 'Songs',
    ko: '곡',
  },
  UNIT_PRACTICE: {
    en: 'Practice',
    ko: '연습',
  },
  UNIT_DAY: {
    en: 'Days',
    ko: '일',
  },
  SUNDAY_SHORT: {
    en: 'SUN',
    ko: '일',
  },
  MONDAY_SHORT: {
    en: 'MON',
    ko: '월',
  },
  TUESDAY_SHORT: {
    en: 'TUE',
    ko: '화',
  },
  WEDNESDAY_SHORT: {
    en: 'WED',
    ko: '수',
  },
  THURSDAY_SHORT: {
    en: 'THU',
    ko: '목',
  },
  FRIDAY_SHORT: {
    en: 'FRI',
    ko: '금',
  },
  SATURDAY_SHORT: {
    en: 'SAT',
    ko: '토',
  },
  PINNED_POSTS: {
    en: 'Pinned Posts',
    ko: '고정해둔 연습노트',
  },
  TAB_SONG: {
    en: 'Song',
    ko: '곡',
  },
  TAB_OTHERS: {
    en: 'Others',
    ko: '그 외',
  },
  SONG: {
    en: 'Song',
    ko: '곡',
  },
  ADDITIONAL_NOTE: {
    en: 'Addional Note',
    ko: '추가사항',
  },
  PLACEHOLDER_ADDITIONAL_NOTE: {
    en: 'Write additional note if you want',
    ko: '추가로 적고 싶은 내용이 있디면 적어주세요',
  },
  ADD_NEW_SONG: {
    en: 'Add a new song',
    ko: '새로운 곡 추가하기',
  },
  ADD_SONG: {
    en: 'Add a Song',
    ko: '곡 추가하기',
  },
  EDIT_SONG: {
    en: 'Edit a Song',
    ko: '곡 수정하기',
  },
  PLACEHOLDER_SONG_SELECTION: {
    en: 'Select a song',
    ko: '곡을 선택해주세요',
  },
  CATEGORY: {
    en: 'Category',
    ko: '카테고리',
  },
  PLACEHOLDER_CATEGORY_SELECTION: {
    en: 'Select a category',
    ko: '카테고리를 선택해주세요',
  },
  NO_PRACTICE_SONG_YET: {
    en: "There's no practice song created yet.",
    ko: '아직 생성된 연습곡이 없습니다.',
  },
  GUIDE_PRACTICE_SONG_CREATION: {
    en: 'Please add a practice song by clicking the Add button above',
    ko: '위의 추가 버튼을 클릭하여 연습곡을 추가해주세요',
  },
  SONG_NAME: {
    en: 'Song name',
    ko: '곡 이름',
  },
  ARTIST: {
    en: 'Artist',
    ko: '아티스트',
  },
  KEY: {
    en: 'Key',
    ko: '키',
  },
  TEMPO: {
    en: 'Tempo',
    ko: '템포',
  },
  PLACEHOLDER_SONG_ADDITIONAL_NOTE: {
    en: 'Write additional note if you want\n\n🎯 Focus: To keep a steady rhythm!',
    ko: '추가로 적고 싶은 내용이 있디면 적어주세요\n\n🎯 집중 포인트: 리듬 안정적으로 유지하기!',
  },
  PRACTICE_STATISTICS: {
    en: 'Practice Statistics',
    ko: '연습 통계',
  },
  ACCOUNT: {
    en: 'Account',
    ko: '계정',
  },
  SIGN_OUT: {
    en: 'Sign Out',
    ko: '로그아웃',
  },
  DELETE_ACCOUNT: {
    en: 'Delete the account',
    ko: '계정 삭제',
  },
  DEVELOPMENT: {
    en: 'Development',
    ko: '개발 관련',
  },
  SEND_FEEDBACK: {
    en: 'Send Feedback',
    ko: '피드백 보내기',
  },
  DEVELOPMENT_STATE_AND_ROADMAPS: {
    en: 'Development State & Roadmaps',
    ko: '개발 상태 및 로드맵',
  },
  OTHERS: {
    en: 'Others',
    ko: '그 외',
  },
  TERMS_OF_SERVICE: {
    en: 'Terms of Service',
    ko: '이용 약관',
  },
  PRIVACY_POLICY: {
    en: 'Privacy Policy',
    ko: '개인정보 처리 방침',
  },
  CUSTOMER_SERVICE: {
    en: 'Customer Service',
    ko: '고객 센터',
  },
  SIGN_IN: {
    en: 'Sign In',
    ko: '로그인',
  },
  SIGN_UP: {
    en: 'Sign Up',
    ko: '회원가입',
  },
  PREFERRED_LANGUAGE: {
    en: 'Preferred Language',
    ko: '언어',
  },
  TERMS_OF_SERVICE_AGREEMENT: {
    en: '(Required) I agree to the Terms of Service',
    ko: '(필수) 이용 약관에 동의합니다',
  },
  PRIVACY_POLICY_AGREEMENT: {
    en: '(Required) I agree to the Privacy Policy',
    ko: '(필수) 개인정보 처리 방침에 동의합니다',
  },
  SONG_DETAILS: {
    en: 'Song Details',
    ko: '곡 정보',
  },
}

// map each key and value of translations to i18n-next resources.

const resources = {
  en: {
    translation: Object.fromEntries(
      Object.entries(translations).map(([key, value]) => [key, value.en]),
    ),
  },
  ko: {
    translation: Object.fromEntries(
      Object.entries(translations).map(([key, value]) => [key, value.ko]),
    ),
  },
}
export default resources
