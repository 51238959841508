import React, { Fragment, useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import {
  Box,
  IconButton,
  AppBar,
  Toolbar,
  TextField,
  Stack,
  Chip,
  Container,
  Paper,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import Input from '@mui/material/Input'
import VolumeUp from '@mui/icons-material/VolumeUp'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  CameraAlt,
  Menu,
  Check,
  Delete,
  DragHandle,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import { useLottie } from 'lottie-react'
import { BLACK, GRAY, GRAY_DARK, GRAY_DARKEST } from '../theme/theme'
import { PracticeTaskOutputDto } from '../types/practiceTask'
import { practiceCategories } from '../PracticeDayView/common'
import { useTranslation } from 'react-i18next'

export interface TaskViewReadOnlyProps {
  task: PracticeTaskOutputDto
}
export default function TaskViewReadonly({ task }: TaskViewReadOnlyProps) {
  const { t } = useTranslation()
  // Increment repetition count
  return (
    <Stack
      spacing={1.3}
      paddingTop={1}
      paddingLeft={1.5}
      paddingRight={1.5}
      paddingBottom={1}
      sx={{
        borderRadius: '10px',
        backgroundColor: 'white',
      }}
    >
      <Stack
        spacing={1.3}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box m={'2px'}>
          <img
            src={
              task.todo.isDone
                ? '/strawberry_icon.png'
                : '/strawberry_unchecked_icon.png'
            }
            width={24}
          />
        </Box>
        <Box flexGrow={1}>
          <Typography
            fontSize={'16px'}
            fontWeight={'bold'}
            fontStyle={{
              color: task.todo.isDone ? GRAY : GRAY_DARKEST,
              textDecoration: task.todo.isDone ? 'line-through' : 'none',
            }}
          >
            {task.practiceTaskCategory === 'SONG'
              ? `${task.practiceSong.title} - ${task.practiceSongArtists
                  .map((artist) => artist.name)
                  .join(', ')}`
              : practiceCategories.find(
                  (category) => category.value === task.practiceTaskCategory,
                )?.label}
          </Typography>

          <div>
            {task.title && (
              <Typography
                fontSize={'16px'}
                fontStyle={{
                  color: task.todo.isDone ? GRAY : GRAY_DARKEST,
                  textDecoration: task.todo.isDone ? 'line-through' : 'none',
                }}
              >
                {task.title}
              </Typography>
            )}
          </div>
        </Box>
      </Stack>
    </Stack>
  )
}
