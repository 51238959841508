import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useRef,
} from 'react'
import { styled } from '@mui/material/styles'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Paper,
  Checkbox,
  FormControlLabel,
  formControlLabelClasses,
  iconClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClasses,
  TextField,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
} from '@mui/icons-material'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import * as timeutil from './util/timeutil'

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_800,
} from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import { YYYYMMDDDateString } from './types/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  PracticeTaskCategory,
  PracticeTaskOutputDto,
} from './types/practiceTask'
import { format, subDays } from 'date-fns'
import { GradientButton, OutlinedButton } from './Components/Button'
import { z } from 'zod'
import { CreatePracticeNoteFormData } from './WritePostPage'
import { PracticeNoteOutputDto } from './types/practiceNote'

// Define props for this page
export interface EditPostPageProps {}

export default function EditPostPage({}: EditPostPageProps) {
  const navigate = useNavigate()
  const contentRef = useRef<HTMLInputElement>(null)
  const topNavRef = useRef<HTMLDivElement>(null)
  const bottomNavRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const [topNavHeight, setTopNavHeight] = useState<number>(0)
  const [bottomNavHeight, setBottomNavHeight] = useState<number>(0)
  const submitButtonRef = useRef<HTMLInputElement | null>(null)
  const emojis = ['🎯', '🔥', '🚀', '🎉', '😃', '🥲', '👍', '👎']
  const location = useLocation()
  const locationState = location.state as {
    practiceNote: PracticeNoteOutputDto
  }
  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
  } = useForm<CreatePracticeNoteFormData>({
    defaultValues: { content: locationState.practiceNote.content },
  })

  useEffect(() => {
    const calculateContentHeight = () => {
      const topNavHeight = topNavRef.current?.offsetHeight || 0
      const bottomNavHeight = bottomNavRef.current?.offsetHeight || 0
      const availableHeight =
        window.innerHeight - topNavHeight - bottomNavHeight

      setContentHeight(availableHeight)
      setTopNavHeight(topNavHeight)
      setBottomNavHeight(bottomNavHeight)
    }

    calculateContentHeight() // Initial calculation

    // Recalculate when the window is resized
    window.addEventListener('resize', calculateContentHeight)

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', calculateContentHeight)
  }, [])

  const onSubmit = async (formData: CreatePracticeNoteFormData) => {
    const handleSavePractice = async () => {
      const resp = await repoutil.put(
        `practiceNotes/${locationState.practiceNote.id}`,
        {
          date: locationState.practiceNote.date,
          ...formData,
        },
      )
      const data = await resp.json()
      console.log(data)
    }
    await handleSavePractice()

    navigate(-1)
  }
  const onDelete = async () => {
    const resp = await repoutil.del(
      `practiceNotes/${locationState.practiceNote.id}`,
    )
    const data = await resp.json()
    console.log(data)

    navigate(-1)
  }
  const generateEmojiClickHandler = (emoji: string) => {
    return (e: SyntheticEvent) => {
      const inputElement = contentRef.current
      const start = inputElement?.selectionStart || 0
      const end = inputElement?.selectionEnd || 0
      const inputValue = inputElement?.value || ''
      const newValue = `${inputValue.slice(0, start)}${emoji}${inputValue.slice(
        end,
      )}`
      setValue('content', newValue)
      inputElement?.focus()
      // @ts-ignore
      inputElement.selectionStart = start + emoji.length
      // @ts-ignore
      inputElement.selectionEnd = start + emoji.length

      e.preventDefault()
    }
  }

  const onClickBackButton = () => {
    navigate(-1)
  }

  return (
    <Box
      height="100vh"
      sx={{
        display: 'flex',
        backgroundColor: GRAY_LIGHTEST,
      }}
    >
      <div
        ref={topNavRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        <AppTitleBar
          leftCornerNode={
            <IconButton
              edge="start"
              aria-label="back"
              onClick={onClickBackButton}
            >
              <ArrowBackIosOutlined sx={{ color: GRAY }} />
            </IconButton>
          }
          rightCornerNode={
            <Typography
              color={GRAY_DARK}
              onClick={(e) => submitButtonRef.current?.click()}
            >
              Save
            </Typography>
          }
          title={'Write'}
          titleCenter={true}
          hideLogo={true}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <input hidden={true} type="submit" ref={submitButtonRef} />
        <Stack
          id="content"
          spacing={2}
          overflow={'scroll'}
          width="100%"
          height={`${contentHeight}px`}
          marginTop={`${topNavHeight}px`}
          p={2}
        >
          <Grid
            container
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
          >
            <Grid xs={12}>
              <Stack pt={1} direction={'row'} justifyContent={'space-evenly'}>
                {emojis.map((emoji) => (
                  <Typography
                    fontSize="24px"
                    onClick={generateEmojiClickHandler(emoji)}
                  >
                    {emoji}
                  </Typography>
                ))}
              </Stack>
            </Grid>
            <Grid xs={12}>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <TextField
                    multiline
                    minRows={4}
                    fullWidth
                    inputRef={contentRef}
                    sx={{
                      backgroundColor: 'white',
                      '& fieldset': { border: 'none' },
                    }}
                    variant="outlined"
                    placeholder="Enter your note here"
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid xs={12}>
            <OutlinedButton
              onClick={onDelete}
              fullWidth
              disableElevation={true}
              type="button"
              variant="outlined"
              sx={{
                fontSize: '16px',
                fontWeight: 'normal',
                borderRadius: '8px',
                color: '#e3756d',
                border: '1px solid #e3756d',
              }}
            >
              Delete
            </OutlinedButton>
          </Grid>
        </Stack>
      </form>
    </Box>
  )
}
