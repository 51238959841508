import React, { useState, useEffect, Fragment, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  TextField,
} from '@mui/material'
import { DevTool } from '@hookform/devtools'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Delete,
  CalendarTodayOutlined,
  CheckOutlined,
} from '@mui/icons-material'
import CalendarView from '../CalendarView'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  BLACK,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
} from '../theme/theme'
import PracticeDayView from '../PracticeDayView/PracticeDayView'
import { getCreateSongPageUrl, getUpdateSongPageUrl } from '../config/config'
import {
  GrayOutlinedButtonSmall,
  OutlinedButton,
  RedOutlinedButtonSmall,
} from '../Components/Button'
import InputWithLabel from '../Components/InputWithLabel'
import { useForm, Controller } from 'react-hook-form'
import { type } from '@testing-library/user-event/dist/type'
import BasePage from '../BasePage'
import {
  practiceSongsState,
  retrievePracticeSongs,
} from '../state/practicesong'
import { useTranslation } from 'react-i18next'
import { E } from '../pixel'

// Execute Create, Update, Delete PracticeSong
export default function WritePracticeSongPage({}) {
  const { t } = useTranslation()
  const location = useLocation()
  const [_practiceSong, setPracticeSong] = useState(
    location.state.practiceSong
      ? location.state.practiceSong
      : {
          title: '',
          artists: [{ id: null, name: '' }],
          songKind: 'RELEASED',
          taskCount: 0,
          routineCount: 0,
          note: '',
        },
  )
  const {
    handleSubmit,
    control,
    getValues,
    formState: { dirtyFields, isDirty },
  } = useForm({
    defaultValues: location.state.practiceSong
      ? location.state.practiceSong
      : {
          title: '',
          artists: [{ id: null, name: '' }],
          songKind: 'RELEASED',
          taskCount: 0,
          musicalKeyOriginal: '',
          musicalKeyArranged: '',
          musicalTempoOriginal: null,
          musicalTempoArranged: null,
          routineCount: 0,
          note: '',
        },
  })
  const submitButtonRef = useRef(null)

  const [practiceSongs, setPracticeSongs] = useRecoilState(practiceSongsState)

  const pathname = useLocation().pathname
  const navigate = useNavigate()

  const isArtistUpdated = () => {
    return dirtyFields.artists?.[0] && dirtyFields.artists[0].name
  }

  const ensureAndGetArtistsRegistered = async () => {
    if (!isArtistUpdated()) return getValues('artists')

    const ensuredArtists = await Promise.all(
      getValues('artists').map(async (artist) => {
        const resp1 = await repoutil.get(`artists?name=${artist.name}`)
        const { data: retrievedArtists } = await resp1.json()
        if (retrievedArtists.length > 0) {
          return retrievedArtists[0]
        }

        const resp2 = await repoutil.post('artists', {
          name: artist.name,
        })
        const data = await resp2.json()
        return data.data
      }),
    )

    return ensuredArtists
  }
  const loadPracticeSongs = async () => {
    await setPracticeSongs(await retrievePracticeSongs())
  }
  const onSubmit = async (data, e) => {
    if (isNewSong()) {
      E('CreatePracticeSong', {
        songName: data.title,
        page: 'WritePracticeSongPage',
      })
      createSong(data)
    } else {
      E('UpdatePracticeSong', {
        songId: data.id,
        songName: data.title,
        page: 'UpdatePracticeSongPage',
      })
      updateSong(data)
    }
  }
  const createSong = async (data) => {
    // create request
    const ensuredArtists = await ensureAndGetArtistsRegistered()
    console.log(data.artists)
    const resp = await repoutil.post('practiceSongs', {
      title: data.title,
      artistIds: ensuredArtists.map((artist) => artist.id),
      songKind: data.songKind,
      note: data.note,
      musicalKeyOriginal: data.musicalKeyOriginal,
      musicalKeyArranged: data.musicalKeyArranged,
      musicalTempoOriginal: data.musicalTempoOriginal,
      musicalTempoArranged: data.musicalTempoArranged,
    })
    const respData = await resp.json()

    loadPracticeSongs()
    navigate(-1)
  }
  const updateSong = async (data) => {
    // update request
    const ensuredArtists = await ensureAndGetArtistsRegistered()
    const resp = await repoutil.put(`practiceSongs/${data.id}`, {
      title: data.title,
      artistIds: ensuredArtists.map((artist) => artist.id),
      songKind: data.songKind,
      note: data.note,
      musicalKeyOriginal: data.musicalKeyOriginal,
      musicalKeyArranged: data.musicalKeyArranged,
      musicalTempoOriginal: data.musicalTempoOriginal,
      musicalTempoArranged: data.musicalTempoArranged,
    })
    const respData = await resp.json()

    loadPracticeSongs()
    navigate(-1)
  }
  const isNewSong = () => !getValues('id')
  const isDeletable = () => {
    return _practiceSong.taskCount == 0 && _practiceSong.routineCount == 0
  }

  const onClickDeleteButton = async (e) => {
    // update request
    const ensuredArtists = await ensureAndGetArtistsRegistered()
    const resp = await repoutil.del(`practiceSongs/${_practiceSong.id}`)
    const data = await resp.json()

    loadPracticeSongs()
    navigate(-1)
  }
  const onClickBackButton = async (e) => {
    loadPracticeSongs()
    navigate(-1)
  }
  const simpleInputs = [
    {
      labelValue: t('SONG_NAME'),
      nameInForm: 'title',
      type: 'text',
      rules: { required: 'Title is required.' },
    },
    {
      labelValue: t('ARTIST'),
      nameInForm: 'artists[0].name',
      type: 'text',
      rules: { required: 'Artists are required.' },
    },
    {
      labelValue: `🎵 ${t('KEY')}`,
      nameInForm: 'musicalKeyOriginal',
      type: 'text',
      placeholder: 'e.g., E Major',
    },
    {
      labelValue: `🥁 ${t('TEMPO')}`,
      nameInForm: 'musicalTempoOriginal',
      type: 'number',
      placeholder: 'in bpm. e.g., 120',
    },
  ]

  const Statistics = () => (
    <Grid container columnGap={1} justifyContent={'space-between'}>
      <Grid item xs={12}>
        <Typography color={GRAY_DARK} fontSize="16px">
          {t('PRACTICE_STATISTICS')}
        </Typography>
      </Grid>
      <Grid container columnGap={1} justifyContent={'space-between'}>
        <Grid item xs={5.5}>
          <Stack
            direction={'row'}
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
            padding={1}
            spacing={2}
            alignItems={'center'}
          >
            <Typography fontSize="18px">🗓️</Typography>
            <Typography color={GRAY} fontSize="18px">
              <span style={{ fontWeight: 'bold', color: GRAY_DARKEST }}>
                {_practiceSong.dayCount}
              </span>{' '}
              {t('UNIT_DAY')}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={5.5}>
          <Stack
            direction={'row'}
            style={{
              backgroundColor: 'white',
              borderRadius: '10px',
            }}
            padding={1}
            spacing={2}
            alignItems={'center'}
          >
            <Typography fontSize="18px">✅</Typography>
            <Typography color={GRAY} fontSize="18px">
              <span style={{ fontWeight: 'bold', color: GRAY_DARKEST }}>
                {_practiceSong.taskCount}
              </span>{' '}
              {t('UNIT_PRACTICE')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
  const Delete = () => {
    return isDeletable() ? (
      <Fragment>
        <RedOutlinedButtonSmall onClick={onClickDeleteButton}>
          {t('DELETE')}
        </RedOutlinedButtonSmall>
      </Fragment>
    ) : (
      <Fragment>
        <GrayOutlinedButtonSmall disabled>Delete</GrayOutlinedButtonSmall>
        <Typography color={GRAY_LIGHT} fontStyle={'italic'}>
          This song cannot be deleted as it is associated with at least one
          task.
          <br />
          {_practiceSong.taskCount} tasks and {_practiceSong.routineCount}{' '}
          routines are related to this song.
        </Typography>
      </Fragment>
    )
  }

  return (
    <BasePage
      stackProps={{ spacing: 1.5 }}
      appTitleBarProps={{
        leftCornerNode: (
          <IconButton
            edge="start"
            aria-label="back"
            onClick={onClickBackButton}
          >
            <ArrowBackIosOutlined sx={{ color: GRAY }} />
          </IconButton>
        ),
        rightCornerNode: (
          <Typography
            color={GRAY_DARKEST}
            onClick={() =>
              submitButtonRef.current && submitButtonRef.current.click()
            }
          >
            {t('SAVE')}
          </Typography>
        ),
        title: isNewSong() ? t('ADD_SONG') : t('EDIT_SONG'),
        titleCenter: true,
        hideLogo: true,
      }}
      child={
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* hidden submit button */}
          <input
            type="submit"
            style={{ display: 'none' }}
            ref={submitButtonRef}
          />
          <Stack spacing={1}>
            {simpleInputs.map((input) => (
              <InputWithLabel
                {...{
                  nameInForm: input.nameInForm,
                  labelWidth: 3,
                  inputWidth: 9,
                  labelValue: input.labelValue,
                  control,
                  variant: 'outlined',
                  rules: input.rules,
                  placeholder: input.placeholder,
                  labelProps: {
                    sx: {
                      color: GRAY_DARK,
                    },
                    fontSize: '15px',
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: 'white',
                      borderRadius: '10px',
                      '& fieldset': { border: 'none' },
                    },
                  },
                  inputInputProps: {
                    sx: {
                      padding: '10px',
                      fontSize: '15px',
                      type: input.type,
                    },
                  },
                }}
              />
            ))}
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange, value } }) => (
                <TextField
                  multiline
                  minRows={4}
                  fullWidth
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    '& fieldset': { border: 'none' },
                  }}
                  variant="outlined"
                  placeholder={t('PLACEHOLDER_SONG_ADDITIONAL_NOTE')}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {!isNewSong() ? Statistics() : null}
            {/* Delete */}
            {!isNewSong() ? Delete() : null}
          </Stack>
          {process.env.NODE_ENV === 'development' && (
            <DevTool control={control} placement="top-right" />
          )}
        </form>
      }
    />
  )
}
