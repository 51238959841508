import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Tab,
  Tabs,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  FormLabel,
  FormControl,
  Slider,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  tabsClasses,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as expressionutil from '../util/expressionutil'
import { YYYYMMDDDateString } from '../types/common'
import { useForm } from 'react-hook-form'
import AddSongTaskFormView from '../PracticeDayView/AddSongTaskFormView'
import AddNonSongTaskFormView from '../PracticeDayView/AddNonSongTaskFormView'
import BasePage from '../BasePage'
import {
  CreateSongPracticeTaskFormData,
  CreateSongPracticeTaskFormDataSchema,
} from '../PracticeDayView/common'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRecoilState } from 'recoil'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { useTranslation } from 'react-i18next'
import useCurrentDate from '../hooks/useCurrentDate'
import usePracticeDayMap from '../hooks/usePracticeDayMap'

export interface AddTaskPageProps {}

export type SongTabKind = {
  label: string
  value: 'SONG'
}

export type OthersTabKind = {
  label: string
  value: 'OTHERS'
}

export type TabKind = SongTabKind | OthersTabKind

export default function AddTaskPage({}: AddTaskPageProps) {
  const { t } = useTranslation()
  const [currentDate] = useCurrentDate()
  const [practiceDayMap, refreshPracticeDay] = usePracticeDayMap()

  const tabs = [
    { label: t('TAB_SONG'), value: 'SONG' },
    { label: t('TAB_OTHERS'), value: 'OTHERS' },
  ]
  const defaultTab = { label: 'Song', value: 'SONG' } as SongTabKind

  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState<TabKind>(defaultTab)
  const onChangeTab = (e: SyntheticEvent, newValue: string) => {
    switch (newValue) {
      case 'SONG':
        setSelectedTab({ label: t('TAB_SONG'), value: 'SONG' } as SongTabKind)
        break
      case 'OTHERS':
        setSelectedTab({
          label: t('TAB_OTHERS'),
          value: 'OTHERS',
        } as OthersTabKind)
        break
      default:
        console.error('Invalid tab value', newValue)
    }
  }

  const _onClose = async () => {
    refreshPracticeDay(currentDate)
    navigate(-1)
  }

  return (
    <BasePage
      appTitleBarProps={{
        leftCornerNode: (
          <IconButton onClick={_onClose}>
            <ArrowBackIosOutlined />
          </IconButton>
        ),
        title: t('ADD_PRACTICE'),
        titleCenter: true,
        hideLogo: true,
      }}
      child={
        <>
          <Tabs
            value={selectedTab.value}
            onChange={onChangeTab}
            variant="fullWidth"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                sx={{ textTransform: 'none' }}
              />
            ))}
          </Tabs>
          {selectedTab.value === 'SONG' && (
            <AddSongTaskFormView date={currentDate} onSubmit={_onClose} />
          )}
          {selectedTab.value === 'OTHERS' && (
            <AddNonSongTaskFormView date={currentDate} onSubmit={_onClose} />
          )}
        </>
      }
      stackProps={{
        sx: { backgroundColor: 'white' },
        py: 0,
        px: 2,
      }}
    ></BasePage>
  )
}
