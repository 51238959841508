import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Dialog,
  DialogContent,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Close,
  NavigateNext,
  NavigateBefore,
} from '@mui/icons-material'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import { GRAY_LIGHTEST } from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import Carousel from 'react-material-ui-carousel'
import { AppUser } from './types/appUser'
import { string } from 'zod'

export default function OnBoardingDaialog() {
  const currentOnboardingVersion = 2
  const { username } = useMemo<{ username: string | null }>(
    () => repoutil.getUserInfo(),
    [],
  )

  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (!username) {
      return
    }
    decideOpen()
  }, [username])

  const decideOpen = async () => {
    // fetch
    const resp = await repoutil.get(`users/${username}`)
    const data = await resp.json()
    const userData = data.data as AppUser

    const completedVersions = userData.completedOnBoardingVersions
    console.log(completedVersions)
    // update
    // WARNING: Before retrieve complete response from the server,
    // onboarding view must not be shown.
    // Be mindful of that.
    setOpen(
      completedVersions !== null &&
        completedVersions !== undefined &&
        !completedVersions.includes(currentOnboardingVersion),
    )
    // When debugging
    // setOpen(true)
  }

  const onClickOnBoardingClose = async (event: SyntheticEvent) => {
    setOpen(false)
    // fetch
    const resp = await repoutil.post(`users/${username}/completeOnBoarding`, {
      onBoardingVersion: currentOnboardingVersion,
    })
    const data = await resp.json()
    const userData = data.data as AppUser
    console.log(
      `completedOnBoardingVersions: ${userData.completedOnBoardingVersions}`,
    )
  }

  const onboardingImagePaths = [
    '/onboarding/1-home.png',
    '/onboarding/2-add-practice.png',
    '/onboarding/3-add-song.png',
    '/onboarding/4-add-song-practice.png',
    '/onboarding/5-practice.png',
  ]

  return (
    <Dialog
      open={open}
      style={{ width: '100%' }}
      PaperProps={{
        style: {
          width: '85%',
          maxHeight: '95%',
          margin: '0px',
          scrollbarWidth: 'none',
          backgroundColor: 'transparent',
          borderRadius: '8px',
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
      }}
    >
      <div
        onClick={onClickOnBoardingClose}
        style={{
          width: '100%',
          zIndex: 1000,
          position: 'absolute',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '6px',
        }}
      >
        <Typography sx={{ color: '#CCCCCC' }}>
          Music Berry 사용법 닫기
        </Typography>
        {/* Place the icon at right */}
        <Close fontSize="medium" sx={{ color: '#CCCCCC' }} />
      </div>
      <DialogContent
        style={{ width: '100%', padding: '0px', scrollbarWidth: 'none' }}
      >
        <Carousel
          autoPlay={false}
          sx={{ width: '100%' }}
          animation="slide"
          swipe={true}
          navButtonsAlwaysVisible={true}
          cycleNavigation={false}
        >
          {onboardingImagePaths.map((path) => (
            <img src={path} width="100%" />
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  )
}
