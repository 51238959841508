import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import * as repoutil from '../util/repoutil'

type NaverSignInCallbackResponse = {
  accessToken: string | undefined
  preferredUsername: string | undefined
  doesUserExist: boolean
}

export default function NaverSignInCallback() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const hash = window.location.hash.substring(1) // Remove the leading '#'
    const params = new URLSearchParams(hash)
    const naverAccessToken = params.get('access_token')
    const state = params.get('state')
    ;(async () => {
      const resp = await repoutil.post('auth/naver/callback', {
        naverAccessToken: naverAccessToken,
        state,
      })
      const data = (await resp.json()).data as NaverSignInCallbackResponse
      if (data.doesUserExist) {
        if (!data.accessToken) {
          console.error(
            'accessToken is not provided even though the user exists',
          )
          return
        }
        console.log('here')
        repoutil.saveAuthentication(data.accessToken)
        navigate(`/home`, {
          replace: true,
        })
        return
      }
      let navigateUrl = `/signup?naverAccessToken=${naverAccessToken}`
      if (data.preferredUsername)
        navigateUrl += `&preferredUsername=${data.preferredUsername}`

      navigate(navigateUrl, { replace: true })
    })()
  }, [])

  return <div />
}
