import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useMemo,
  useRef,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Dialog,
  DialogContent,
  Paper,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Close,
  NavigateNext,
  NavigateBefore,
} from '@mui/icons-material'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import { GRAY_DARKEST, GRAY_LIGHT, GRAY_LIGHTEST, GREEN } from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import Carousel from 'react-material-ui-carousel'
import { AppUser } from './types/appUser'
import { string } from 'zod'
import {
  CreatePracticeSongFromSharedPracticeSongInput,
  SharedPracticeSongOutputDto,
} from './types/sharedPracticeSong'
import { GradientButton } from './Components/Button'

const MAX_SELECTION_NUMBER = 3

export interface OnBoardingPracticeSongPage {}

export default function OnBoardingPracticeSongPage({}: OnBoardingPracticeSongPage) {
  const navigate = useNavigate()
  const topNavRef = useRef<HTMLDivElement>(null)
  const bottomNavRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<number>(0)
  const [topNavHeight, setTopNavHeight] = useState<number>(0)

  const [selectedSongIds, setSelectedSongIds] = useState<number[]>([])
  const [sharedPracticeSongs, setSharedPracticeSongs] = useState<
    SharedPracticeSongOutputDto[]
  >([])

  useEffect(() => {
    const fetchSharedPracticeSongs = async () => {
      const resp = await repoutil.get('sharedPracticeSongs')
      const data = await resp.json()
      setSharedPracticeSongs(data.data)
    }
    fetchSharedPracticeSongs()
  }, [])

  const createSong = async (sharedPracticeSongId: number) => {
    const resp = await repoutil.post(
      `practiceSongs/createFromSharedPracticeSong`,
      { sharedPracticeSongId } as CreatePracticeSongFromSharedPracticeSongInput,
    )
    const data = await resp.json()
  }

  const onClickComplete = async (event: SyntheticEvent) => {
    for (const sharedPracticeSongId of selectedSongIds) {
      await createSong(sharedPracticeSongId)
    }
    navigate('/home', {
      replace: true,
    })
  }

  useEffect(() => {
    const calculateContentHeight = () => {
      const topNavHeight = topNavRef.current?.offsetHeight || 0
      const bottomNavHeight = bottomNavRef.current?.offsetHeight || 0
      const availableHeight =
        window.innerHeight - topNavHeight - bottomNavHeight

      setContentHeight(availableHeight)
      setTopNavHeight(topNavHeight)
    }

    calculateContentHeight() // Initial calculation

    // Recalculate when the window is resized
    window.addEventListener('resize', calculateContentHeight)

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', calculateContentHeight)
  }, [])
  return (
    <Box
      height="100vh"
      sx={{
        display: 'flex',
        backgroundColor: '#FFFFFF',
      }}
    >
      <div
        ref={topNavRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          margin: '20px',
        }}
      >
        <Grid container>
          <Grid item xs={10}>
            <Typography fontSize="32px" color={GREEN} fontWeight={'bold'}>
              Choose songs
              <br />
              to practice!
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              style={{
                fontSize: '22px',
                textAlign: 'right',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  color: GREEN,
                }}
              >
                {selectedSongIds.length}
              </span>
              <span
                style={{
                  color: GRAY_LIGHT,
                }}
              >
                {' '}
                / {MAX_SELECTION_NUMBER}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Stack
        id="content"
        spacing={2}
        overflow={'scroll'}
        width="100%"
        height={`${contentHeight}px`}
        marginTop={`${topNavHeight}px`}
        p={2}
        style={{
          scrollbarWidth: 'none',
        }}
      >
        <Grid container>
          {/* create rows containing each 2 sharedPracticeSongs */}

          <Grid item xs={6}></Grid>
        </Grid>
      </Stack>
      <Paper
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '20px',
          backgroundColor: 'transparent',
        }}
        ref={bottomNavRef}
        elevation={0}
      >
        <GradientButton
          onClick={onClickComplete}
          disableElevation={true}
          fullWidth
          sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            borderRadius: '8px',
          }}
          disabled={selectedSongIds.length !== 0}
        >
          Complete
        </GradientButton>
      </Paper>
    </Box>
  )
}
