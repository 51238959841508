import React, {
  Fragment,
  useState,
  useEffect,
  SyntheticEvent,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Backdrop,
  LinearProgress,
  Container,
  Button,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
} from '@mui/icons-material'

import * as dateutil from './util/dateutil'
import * as repoutil from './util/repoutil'
import { useRecoilState } from 'recoil'
import { YYYYMMDDDateString } from './types/common'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import usePracticeDayMap from './hooks/usePracticeDayMap'
import useCurrentDate from './hooks/useCurrentDate'

// dates는 이 주의 날짜들을 의미.
export interface CalendarWeeklyViewProps {
  dates: Date[]
}

export default function CalendarWeeklyView({ dates }: CalendarWeeklyViewProps) {
  console.log('render CalanderWeeklyView')
  const [practiceDayMap, refreshPracticeDay] = usePracticeDayMap()
  const practiceDaysOfWeek = useMemo(() => {
    return dates.map((date) => practiceDayMap.get(dateutil.formatDate(date)))
  }, [practiceDayMap])

  const [dateOfToday] = useState<YYYYMMDDDateString>(
    dateutil.formatDate(new Date()),
  )
  const [currentDate, setCurrentDate] = useCurrentDate()

  const getColorOfDailyBox = (cnt = 0) => {
    const minColor = ['FF', 'FF', 'FF']
    const maxColor = ['24', 'B6', '0C']
    const emptyColor = '#D9D9D9'

    if (cnt == 0) {
      return emptyColor
    }
    const maxDuration = 10
    const resultColor = [] as string[]
    minColor
      .map((e, i) => [e, maxColor[i]])
      .forEach(([min, max]) => {
        const totalColorDiff = parseInt(min, 16) - parseInt(max, 16)
        const colorDiff = Math.floor(
          totalColorDiff * Math.min(cnt / maxDuration, 1),
        )
        resultColor.push(
          (parseInt(min, 16) - colorDiff).toString(16).padStart(2, '0'),
        )
      })
    return '#' + resultColor.join('')
  }

  const getPracticeCountOfDay = (pd?: PracticeDayDetailedOutputDto) => {
    if (!pd) {
      return 0
    }

    return pd.practiceTasks.reduce((total, task) => {
      return total + (task.task.todo.isDone ? 1 : 0)
    }, 0)
  }

  const generateOnClickDateHandler = (date?: YYYYMMDDDateString) => {
    return () => {
      if (!date) {
        return () => {}
      }

      setCurrentDate(date)
    }
  }

  const getBoxShadow = (date?: YYYYMMDDDateString) => {
    return dateOfToday == date ? '0 0 0 2px #33A000 inset' : null
  }

  return (
    <Stack
      m={10}
      direction={'row'}
      spacing={1.5}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {practiceDaysOfWeek.map((pd, i) => {
        return (
          <Box
            key={i}
            sx={{
              backgroundColor: getColorOfDailyBox(getPracticeCountOfDay(pd)),
              width: '28px',
              height: '28px',
              borderRadius: '4px',
              boxShadow: getBoxShadow(pd?.date),
              display: 'flex',
            }}
            onClick={generateOnClickDateHandler(pd?.date)}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography fontStyle={{ textAlign: 'center' }}>
              {pd?.date === currentDate ? '✅' : ''}
            </Typography>
          </Box>
        )
      })}
    </Stack>
  )
}
