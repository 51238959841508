import React, {
  useState,
  useEffect,
  Fragment,
  SyntheticEvent,
  useRef,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  Paper,
  Checkbox,
  FormControlLabel,
  formControlLabelClasses,
  iconClasses,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClasses,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
} from '@mui/icons-material'
import AppTitleBar from './AppTitleBar'
import CalendarView from './CalendarView'
import CustomBottomNavigation from './CustomBottomNavigation'
import * as repoutil from './util/repoutil'
import * as dateutil from './util/dateutil'
import * as timeutil from './util/timeutil'

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_800,
} from './theme/theme'
import PracticeDayView from './PracticeDayView/PracticeDayView'
import { PracticeDayDetailedOutputDto } from './types/practiceDay'
import { YYYYMMDDDateString } from './types/common'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  PracticeTaskCategory,
  PracticeTaskOutputDto,
} from './types/practiceTask'
import { format, subDays } from 'date-fns'
import { GradientButton, OutlinedButton } from './Components/Button'
import { PracticeNoteOutputDto } from './types/practiceNote'
import PracticeNoteListView, {
  PracticeNoteListQueryType,
} from './PracticeNoteListView'
import BasePage from './BasePage'
import { ImportPracticePageProps } from './Practice/ImportPracticePage'
import { getPracticePageUrl } from './config/config'
import useCurrentDate from './hooks/useCurrentDate'
import usePracticeDayMap from './hooks/usePracticeDayMap'
import { useTranslation } from 'react-i18next'
import OnBoardingDaialog from './OnBoardingDialog'
import ReactPixel from 'react-facebook-pixel'
import { ClickMoveButton } from './pixel'

// Define props for this page
export interface HomePageProps {}

export default function HomePage({}: HomePageProps) {
  const { t } = useTranslation()
  const [practiceDayMap, refreshPracticeDay] = usePracticeDayMap()
  const [todayDate, setTodayDate] = useState<YYYYMMDDDateString>(
    dateutil.nowYYYYMMDD(),
  )
  const practiceDay = useMemo(() => {
    return practiceDayMap.get(todayDate)
  }, [practiceDayMap, todayDate])

  const navigate = useNavigate()

  const onClickStartPracticeButton = async (e: SyntheticEvent) => {
    ClickMoveButton('HomePage', 'PracticePage')
    navigate(getPracticePageUrl())
  }

  const onClickImportPracticeButton = async (e: SyntheticEvent) => {
    ClickMoveButton('HomePage', 'ImportPracticePage')
    if (!practiceDay) return

    navigate(`/practice/import?date=${todayDate}`, {
      state: {} as ImportPracticePageProps,
    })
  }

  const onClickWritePostButton = async (e: SyntheticEvent) => {
    ClickMoveButton('HomePage', 'WritePostPage')
    if (!practiceDay) return
    navigate(`/posts/write?date=${todayDate}`)
  }

  return (
    <BasePage
      child={
        <>
          <OnBoardingDaialog />
          <PracticeNoteListView
            date={todayDate}
            queryType={PracticeNoteListQueryType.PINNED_ONLY_EXCLUDING_DATE}
          />
          <Stack
            spacing={1}
            padding={2}
            style={{ borderRadius: '8px', backgroundColor: 'white' }}
          >
            <Typography
              fontSize={'18px'}
              fontWeight={'bold'}
              color={GRAY_DARKEST}
            >
              {t('TODAY_PRACTICE')}
            </Typography>
            {(practiceDay ? practiceDay.practiceTasks : []).length < 1 ? (
              <>
                <Typography
                  fontSize="16px"
                  color={GRAY}
                  style={{ marginBottom: '4px' }}
                >
                  {t('NO_PRACTICE_YET')}
                </Typography>

                <GradientButton
                  onClick={onClickImportPracticeButton}
                  disableElevation={true}
                  fullWidth
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                  }}
                >
                  {t('IMPORT_PRACTICE')}
                </GradientButton>
                <OutlinedButton
                  onClick={onClickStartPracticeButton}
                  disableElevation={true}
                  fullWidth
                  variant="outlined"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                  }}
                >
                  {t('START_PRACTICE')}
                </OutlinedButton>
              </>
            ) : (
              <>
                <Stack direction={'column'}>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                  >
                    <Grid xs={5.5}>
                      <Typography fontSize="16px" color={GRAY}>
                        {t('COMPLETED_PRACTICE')}
                      </Typography>
                    </Grid>
                    <Grid xs={4.5}>
                      <Typography
                        fontSize="28px"
                        color={GRAY_DARKEST}
                        fontWeight={'bold'}
                        textAlign={'center'}
                      >
                        <span style={{ color: GREEN }}>
                          {practiceDay?.practiceTasks.reduce(
                            (acc, task) =>
                              acc + (task.task.todo.isDone ? 1 : 0),
                            0,
                          )}
                        </span>
                        <span>{` / ${practiceDay?.practiceTasks.length}`}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                  >
                    <Grid xs={5.5}>
                      <Typography fontSize="16px" color={GRAY}>
                        {t('PRACTICE_DURATION')}
                      </Typography>
                    </Grid>
                    <Grid xs={4.5}>
                      <Typography
                        fontSize="28px"
                        color={GREEN}
                        fontWeight={'bold'}
                        textAlign={'center'}
                      >
                        {timeutil.formatDuration(
                          ...timeutil.convertMsToDuration(
                            practiceDay
                              ? practiceDay.practiceDuration * 1000
                              : 0,
                          ),
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
                <GradientButton
                  onClick={onClickStartPracticeButton}
                  disableElevation={true}
                  fullWidth
                  sx={{
                    fontSize: '18px',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                  }}
                >
                  {t('MOVE_TO_PRACTICE')}
                </GradientButton>
              </>
            )}
          </Stack>
          <Stack
            spacing={1}
            padding={2}
            style={{ borderRadius: '8px', backgroundColor: 'white' }}
          >
            <Typography
              fontSize={'18px'}
              fontWeight={'bold'}
              color={GRAY_DARKEST}
            >
              {t('TODAY_POSTS')}
            </Typography>

            <PracticeNoteListView
              date={todayDate}
              itemProps={{ sx: { backgroundColor: GRAY_LIGHTEST } }}
              queryType={PracticeNoteListQueryType.DATE_ONLY}
              fallback={
                <Typography
                  fontSize="16px"
                  color={GRAY}
                  style={{ marginBottom: '4px' }}
                >
                  {t('NO_POSTS_YET')}
                </Typography>
              }
            />

            <OutlinedButton
              onClick={onClickWritePostButton}
              disableElevation={true}
              fullWidth
              variant="outlined"
              sx={{ fontSize: '18px', fontWeight: 'bold', borderRadius: '8px' }}
            >
              {t('WRITE_POST')}
            </OutlinedButton>
          </Stack>
        </>
      }
    />
  )
}
