import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  SyntheticEvent,
  MouseEvent,
} from 'react'
import { styled } from '@mui/material/styles'
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Drawer,
  SwipeableDrawer,
  Fab,
  TextField,
  Accordion,
  AccordionSummary,
  Chip,
  AccordionDetails,
  accordionSummaryClasses,
} from '@mui/material'
import { green } from '@mui/material/colors'
import {
  ExpandMore,
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Add,
  Delete,
  CalendarTodayOutlined,
  CheckOutlined,
} from '@mui/icons-material'
import AppTitleBar from '../AppTitleBar'
import CalendarView from '../CalendarView'
import CustomBottomNavigation from '../CustomBottomNavigation'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'
import {
  BLACK,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN_LIGHTEST,
} from '../theme/theme'
import PracticeDayView from '../PracticeDayView/PracticeDayView'
import { getCreateSongPageUrl, getUpdateSongPageUrl } from '../config/config'
import {
  GrayOutlinedButtonSmall,
  OutlinedButton,
  RedOutlinedButtonSmall,
} from '../Components/Button'
import InputWithLabel from '../Components/InputWithLabel'
import { useForm, Controller } from 'react-hook-form'
import { type } from '@testing-library/user-event/dist/type'
import { CreatePracticeSongInputDto, SongKind } from '../types/practiceSong'
import { DevTool } from '@hookform/devtools'
import { ErrorMessage } from '@hookform/error-message'
import { ArtistIdentityDto, ArtistIdentityNotExistable } from '../types/artist'
import PracticeTaskGroupHeader from './SongPracticeTaskHeader'
import SongPracticeTaskHeader from './SongPracticeTaskHeader'
import { YYYYMMDDDateString } from '../types/common'
import NonSongPracticeTaskHeader from './NonSongPracticeTaskHeader'
import TaskViewReadonly from '../TaskView/TaskViewReadonly'
import {
  PracticeDayDetailedOutputDto,
  PracticeDayPracticeTaskDto,
} from '../types/practiceDay'
import { PracticeTaskCategory } from '../PracticeDayView/common'
import TaskView, { onTaskDone } from '../TaskView/TaskView'

export interface PracticeTaskListViewProps {
  practiceTasks: PracticeDayPracticeTaskDto[]
  fallback?: JSX.Element
  readonly?: boolean
  onTaskDone?: onTaskDone
}

// Execute Create, Update, Delete PracticeSong
export default function PracticeTaskListView({
  practiceTasks,
  fallback,
  readonly = true,
  onTaskDone = () => {},
}: PracticeTaskListViewProps) {
  return (
    <>
      {practiceTasks.length > 0 ? (
        <Stack spacing={2}>
          {practiceTasks.map((practiceTask, i) => (
            <Grid
              container
              key={`group-${JSON.stringify(practiceTask.task.id)})}`}
              rowGap={0.6}
            >
              <Grid item xs={12}>
                {/* {practiceTask.task.practiceTaskCategory ===
                  PracticeTaskCategory.Enum.SONG && practiceTask.song ? (
                  readonly ? (
                    <SongPracticeTaskHeader song={practiceTask.song} />
                  ) : (
                    <PracticeSongView practiceSongId={practiceTask.song.id} />
                  )
                ) : (
                  <NonSongPracticeTaskHeader task={practiceTask} />
                )} */}
              </Grid>
              <Grid item xs={12}>
                {readonly ? (
                  <TaskViewReadonly
                    key={'practiceTask' + practiceTask.task.id}
                    task={practiceTask.task}
                  />
                ) : (
                  <TaskView
                    key={'practiceTask' + practiceTask.task.id}
                    _task={practiceTask.task}
                    onTaskDone={onTaskDone}
                  />
                )}
              </Grid>
            </Grid>
          ))}
        </Stack>
      ) : (
        fallback
      )}
    </>
  )
}
