import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
  useMemo,
  ReactNode,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'
import Select from 'react-select'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Tab,
  Tabs,
  MenuItem,
  InputLabel,
  TextField,
  FormLabel,
  FormControl,
  Slider,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  ButtonProps,
  RadioGroup,
  Radio,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Refresh,
} from '@mui/icons-material'
import TaskView from '../TaskView/TaskView'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as expressionutil from '../util/expressionutil'
import { SelectOption, YYYYMMDDDateString } from '../types/common'
import { Controller, useForm } from 'react-hook-form'
import {
  CreateSongFormDataSchema,
  CreateSongFormData,
  CreateSongPracticeTaskFormData,
  SongDefiningMethod,
} from '../PracticeDayView/common'
import { PracticeSongOutputDto } from '../types/practiceSong'
import { GradientButton } from '../Components/Button'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  GRAY,
  GRAY_DARK,
  GRAY_LIGHT,
  GREEN,
  GREEN_800,
  GREEN_LIGHT,
  GREEN_LIGHTEST,
} from '../theme/theme'
import { ErrorMessage } from '@hookform/error-message'
import { getCreateSongPageUrl } from '../config/config'
import { DevTool } from '@hookform/devtools'
import { useTranslation } from 'react-i18next'
import { E } from '../pixel'

export interface CreateSongProps {
  onSubmit: () => void
}

export default function CreateSong({ onSubmit }: CreateSongProps) {
  const { t } = useTranslation()
  const [openSongCreate, setOpenSongCreate] = useState(true)
  const { handleSubmit, control, register, getValues } =
    useForm<CreateSongFormData>({
      resolver: zodResolver(CreateSongFormDataSchema),
    })

  const _onSubmit = async (formData: CreateSongFormData) => {
    E('CreatePracticeSong', {
      songName: formData.title,
      page: 'PracticePage',
    })
    const resp = await repoutil.post('practiceSongs', {
      ...formData,
      songKind: 'RELEASED',
      musicalKeyOriginal: '',
      musicalKeyArranged: '',
      musicalTempoOriginal: null,
      musicalTempoArranged: null,
      note: '',
    })
    const data = await resp.json()
    console.log(data)
    onSubmit()
  }

  const inputList: { title: string; name: 'title' | 'artist' }[] = [
    { title: t('SONG_NAME'), name: 'title' },
    { title: t('ARTIST'), name: 'artist' },
  ]

  return (
    <>
      <form>
        <Box className="form-input-box" pt={2} pb={3}>
          <Stack spacing={2}>
            <div>
              {inputList.map((item) => (
                <>
                  <div className="flex">
                    <p className="flex-grow text-gray-800 mb-1">{item.title}</p>
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name={item.name}
                      render={({ field, formState: { errors } }) => (
                        <>
                          <TextField
                            placeholder=""
                            fullWidth={true}
                            margin="dense"
                            inputProps={{
                              style: {
                                paddingTop: '8px',
                                paddingBottom: '8px',
                              },
                            }}
                            {...field}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={field.name}
                            render={({ message }) => (
                              <Typography variant={'caption'} color={'error'}>
                                {`${message}`}
                              </Typography>
                            )}
                          />
                        </>
                      )}
                    />
                  </div>
                </>
              ))}
            </div>
            {/* <Typography>최근 추가한 연습</Typography> */}
          </Stack>
        </Box>
        <GradientButton
          fullWidth
          onClick={handleSubmit(_onSubmit)}
          variant="contained"
          style={{
            fontSize: '16px',
            textTransform: 'none',
            borderRadius: '10px',
            fontWeight: 'bold',
          }}
          disableElevation={true}
        >
          Add
        </GradientButton>
        {process.env.NODE_ENV === 'development' && (
          <DevTool control={control} placement="top-right" />
        )}
      </form>
    </>
  )
}
