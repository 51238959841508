import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import * as amplitude from '@amplitude/analytics-browser'

export default function EventTracker() {
  const location = useLocation()

  useEffect(() => {}, [location])

  return <div />
}
