import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  SyntheticEvent,
  useMemo,
} from 'react'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, faListCheck } from '@fortawesome/free-solid-svg-icons'
import { faClock, faNoteSticky } from '@fortawesome/free-regular-svg-icons'
import Carousel from 'react-material-ui-carousel'
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Box,
  Avatar,
  Stack,
  Divider,
  Backdrop,
  LinearProgress,
  Container,
  Button,
  Paper,
  Drawer,
  SwipeableDrawer,
  Alert,
  Card,
  Chip,
} from '@mui/material'
import {
  Create,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Menu,
  Visibility,
  VisibilityOff,
  Note,
  AccessTime,
  PlayArrow,
  Pause,
  Close,
  PushPin,
} from '@mui/icons-material'
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil'

import TaskView from '../TaskView/TaskView'
import TaskViewReadonly from '../TaskView/TaskViewReadonly'
import * as repoutil from '../util/repoutil'
import * as dateutil from '../util/dateutil'
import * as timeutil from '../util/timeutil'
import * as expressionutil from '../util/expressionutil'

import {
  GRADIENT_LIGHT_GREEN,
  GRAY,
  GRAY_DARK,
  GRAY_DARKEST,
  GRAY_LIGHT,
  GRAY_LIGHTEST,
  GREEN,
  GREEN_LIGHTEST,
} from '../theme/theme'
import { OutlinedButton } from '../Components/Button'

import { PracticeTaskOutputDto } from '../types/practiceTask'
import PracticeTaskGroupHeader from '../Practice/SongPracticeTaskHeader'
import SongPracticeTaskHeader from '../Practice/SongPracticeTaskHeader'
import NonSongPracticeTaskHeader from '../Practice/NonSongPracticeTaskHeader'
import { PracticeTaskCategory } from './common'
import { PracticeNoteOutputDto } from '../types/practiceNote'
import { on } from 'events'
import PracticeNoteListView, {
  PracticeNoteListQueryType,
} from '../PracticeNoteListView'
import PracticeSongListView from '../Practice/PracticeTaskListView'

import { getPracticePageUrl } from '../config/config'
import useCurrentDate from '../hooks/useCurrentDate'
import usePracticeDayMap from '../hooks/usePracticeDayMap'
import { useTranslation } from 'react-i18next'

// date props는 new Date()와 동일한 타입
export default function PracticeDayView() {
  // XXX(Jinsu Park): 2024-12-31 기준 이것이 Best Practice임.
  const [currentDate] = useCurrentDate()
  const [practiceDayMap, refreshPracticeDay] = usePracticeDayMap()
  const practiceDay = useMemo(() => {
    return practiceDayMap.get(currentDate)
  }, [practiceDayMap, currentDate])

  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!practiceDay) {
    return <div>loading...</div>
  }

  // date args는 new Date()와 동일한 데이터 타입
  // 이 메소드를 언제 쓸지는 고민
  const compareDateWithToday = (date: Date) => {
    const today = new Date()
    if (!date) {
      console.error('date is falsy', date)
    }

    const todayString = dateutil.formatDate(today)
    const dateString = dateutil.formatDate(date)

    if (dateString == todayString) return 0
    else if (dateString < todayString) return -1
    else return 1
  }

  const handleClick = (e: SyntheticEvent) => {
    navigate(getPracticePageUrl(), {})
  }

  const PracticeDayStateView = (
    <Grid
      container
      p={1}
      pt={2}
      // Without note, 1 feels too narrow.
      pb={1}
      borderRadius={2}
      sx={{ background: GRADIENT_LIGHT_GREEN }}
      rowGap={2}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems={'center'}
      >
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faClock}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'18px'}
            textAlign={'center'}
            color={GREEN}
            fontWeight={'bold'}
          >
            {timeutil.formatDuration(
              ...timeutil.convertMsToDuration(
                practiceDay.practiceDuration * 1000,
              ),
            )}
          </Typography>
        </Grid>
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faNoteSticky}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'14px'}
            textAlign={'center'}
            color={GRAY_DARKEST}
          >
            <span
              style={{
                fontSize: '18px',
                color: GREEN,
                fontWeight: 'bold',
              }}
            >
              {practiceDay.practiceNotes.length}
            </span>
            {` ${t('UNIT_POST')}`}
          </Typography>
        </Grid>
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faMusic}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'14px'}
            textAlign={'center'}
            color={GRAY_DARKEST}
          >
            <span
              style={{
                fontSize: '18px',
                color: GREEN,
                fontWeight: 'bold',
              }}
            >
              {
                new Set(
                  practiceDay.practiceTasks
                    .map((task) => task.song?.id)
                    .filter((id) => id),
                ).size
              }
            </span>
            {` ${t('UNIT_SONG')}`}
          </Typography>
        </Grid>
        <Grid item xs={3} justifyContent="center">
          <FontAwesomeIcon
            icon={faListCheck}
            style={{
              width: '100%',
              height: '32px',
              color: GRAY_DARKEST,
            }}
          />
          <Typography
            fontSize={'14px'}
            textAlign={'center'}
            color={GRAY_DARKEST}
          >
            <span
              style={{
                color: GREEN,
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {practiceDay.practiceTasks.reduce(
                (acc: number, practiceTask) =>
                  acc + (practiceTask.task.todo.isDone ? 1 : 0),
                0,
              )}
            </span>
            {` ${t('UNIT_PRACTICE')}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Grid container>
      {/* Header container */}
      <Grid item xs={12}></Grid>
      {/* Body container */}
      <Grid item xs={12}>
        <Grid container rowGap={2}>
          <Grid item xs={12}>
            {PracticeDayStateView}
          </Grid>
          {practiceDay.practiceNotes.length > 0 && (
            <Grid item xs={12}>
              <PracticeNoteListView
                date={practiceDay.date}
                queryType={PracticeNoteListQueryType.DATE_ONLY}
              />
            </Grid>
          )}
          <Grid item xs={12} onClick={handleClick}>
            <PracticeSongListView
              practiceTasks={practiceDay.practiceTasks}
              fallback={
                <OutlinedButton
                  fullWidth
                  disableElevation={true}
                  type="button"
                  variant="outlined"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'normal',
                    borderRadius: '8px',
                    color: GREEN,
                  }}
                >
                  {t('MOVE_TO_PRACTICE')}
                </OutlinedButton>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {/* Footer container */}
      <Grid item xs={12}>
        <Grid container></Grid>
      </Grid>
    </Grid>
  )
}
