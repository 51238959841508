import { SetterOrUpdater, useRecoilState } from 'recoil'
import { currentDateState, practiceDayMapState } from '../state/practiceday'
import { useEffect, useState } from 'react'
import { PracticeDayDetailedOutputDto } from '../types/practiceDay'
import { getDateRange } from '../util/dateutil'
import { YYYYMMDDDateString } from '../types/common'
import { retrievePracticeDay, retrievePracticeDays } from '../util/repoutil'

export default function useCurrentDate(): [
  YYYYMMDDDateString,
  SetterOrUpdater<YYYYMMDDDateString>,
] {
  const [currentDate, setCurrentDate] = useRecoilState(currentDateState)
  return [currentDate, setCurrentDate]
}
