export function languageCode(language?: string): string {
  switch (language) {
    case 'KOREAN':
      return 'ko'
    case 'ENGLISH':
      return 'en'
    default:
      return 'en'
  }
}

export function languageName(languageCode: string): string {
  switch (languageCode) {
    case 'ko':
    case 'ko-KR':
      return 'KOREAN'
    case 'en':
    case 'en-US':
      return 'ENGLISH'
    default:
      return 'ENGLISH'
  }
}
