import ReactPixel from 'react-facebook-pixel'
import { getUserInfo } from './util/repoutil'
import * as amplitude from '@amplitude/analytics-browser'

// CE(Custom Event)
export function E(eventName: string, data: object) {
  amplitude.logEvent(eventName, {
    ...data,
  })
}

export function ClickMoveButton(from: string, to: string) {
  E('ClickMoveButton', {
    from: from,
    to: to,
  })
}
